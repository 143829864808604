import { request, getHost } from './utils';

export const drawAward = (token, id) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/activity/${id}/award`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${token}`
    }
  };
  return request(options);
};

export const n = () => {};
