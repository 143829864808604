import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Drawer from 'antd/es/drawer';

function BottomDrawer(props) {
  const {
    onClose,
    visible,
    children
  } = props;

  const placement = window.innerWidth > 768 ? 'right' : 'bottom';

  return (
    <Drawer
      placement={placement}
      closable
      onClose={onClose}
      visible={visible}
      key="bottom_drawer"
      bodyStyle={{ padding: '45px 30px 36px' }}
      height="min(576px, 100%)"
      width="min(576px, 100%)"
    >
      {children}
    </Drawer>
  );
}

BottomDrawer.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

BottomDrawer.defaultProps = {
  children: null,
  visible: false,
  onClose: () => {}
};

BottomDrawer.inStorybook = true;

export default observer(BottomDrawer);
