import React from 'react';
import PropTypes from 'prop-types';

import { App, PIXI } from '../../../../../modules/PIXI';
import gsap from '../../../../../modules/GSAP';
import AssetsManager from '../../../../../modules/AssetsManager';
import assets from './assets';
import { SLOT_MACHINE_RESULT } from '../../../../../viewModels/ProgramViewModel';

const FPS = 60;

export default class SlotMachine extends React.Component {

  canvas = React.createRef();

  container = null;

  machine = null;

  handle = null;

  stripe1 = null;

  stripe2 = null;

  stripe3 = null;

  musk1 = null;

  musk2 = null;

  musk3 = null;

  text = null;

  isDrew = false;

  isRunning = true;


  constructor(props) {
    super(props);

    App.ticker.speed = FPS / 60;

    gsap.ticker.remove(gsap.updateRoot); // ! Note
  }

  async componentDidMount() { // init
    AssetsManager.reset();

    await AssetsManager.loadAll(assets);
    const group = AssetsManager.getResource('stripe');

    this.canvas.current?.appendChild(App.view);

    this.container = new PIXI.Container();
    this.container.interactive = true;
    this.container.buttonMode = true;
    this.container.on('touchend', this.onClick);
    this.container.on('mousedown', this.onClick);

    // ========= machine animation =========

    const machineTextures = [];

    for (let i = 1; i < 3; i += 1) {
      const framekey = `machine0${i}`;
      const texture = PIXI.Texture.from(framekey);
      machineTextures.push(texture);
    }

    this.machine = new PIXI.AnimatedSprite(machineTextures);
    this.machine.position.set(-25, -10);
    this.machine.animationSpeed = 0.2;
    this.machine.play();
    this.container.addChild(this.machine);


    //  ========= handle animation =========

    const handleTextures = [];

    for (let i = 1; i < 13; i += 1) {
      const framekey = `handle0${i}`;
      const texture = PIXI.Texture.from(framekey);
      handleTextures.push(texture);
    }

    this.handle = new PIXI.AnimatedSprite(handleTextures);
    this.handle.position.set(284, 64);
    this.handle.animationSpeed = 0.3;
    this.handle.loop = false;
    this.container.addChild(this.handle);



    //  ========= stripe 01 =========

    this.stripe1 = new PIXI.TilingSprite(group.texture, App.screen.width / 11, App.screen.height);
    this.stripe1.x = 59;
    this.stripe1.y = -10;
    this.container.addChild(this.stripe1);

    this.musk1 = new PIXI.Graphics();
    this.musk1.beginFill(0xDE3249);
    this.musk1.drawRoundedRect(59, 142, 65, 94, 10);
    this.musk1.endFill();
    this.container.addChild(this.musk1);

    this.stripe1.mask = this.musk1;



    //  ========= stripe 02 =========

    this.stripe2 = new PIXI.TilingSprite(group.texture, App.screen.width / 11, App.screen.height);
    this.stripe2.x = 128;
    this.stripe2.y = 68;
    this.container.addChild(this.stripe2);

    this.musk2 = new PIXI.Graphics();
    this.musk2.beginFill(0xDE3249);
    this.musk2.drawRoundedRect(128, 142, 65, 94, 10);
    this.musk2.endFill();
    this.container.addChild(this.musk2);

    this.stripe2.mask = this.musk2;



    //  ========= stripe 03 =========

    this.stripe3 = new PIXI.TilingSprite(group.texture, App.screen.width / 11, App.screen.height);
    this.stripe3.x = 199;
    this.stripe3.y = 144;
    this.container.addChild(this.stripe3);

    this.musk3 = new PIXI.Graphics();
    this.musk3.beginFill(0xDE3249);
    this.musk3.drawRoundedRect(198, 142, 65, 94, 10);
    this.musk3.endFill();
    this.container.addChild(this.musk3);

    this.stripe3.mask = this.musk3;


    //  ========= text =========

    this.text = new PIXI.Text('限時拉霸', new PIXI.TextStyle({
      fill: '#ffffff',
      fontSize: 28,
      fontWeight: 'bold',
      align: 'center'
    }));
    this.text.anchor.set(0.5);
    this.text.x = 160;
    this.text.y = 80;

    this.container.addChild(this.text);




    App.stage.addChild(this.container);
    App.ticker.add(this.loop);
    App.start();
  }

  componentWillUnmount() { // destory
    this.isRunning = false;
    App.stop();
    App.ticker.remove(this.loop);

    gsap.killTweensOf(this);

    this.container.removeChildren();
    this.container.destroy(true);
    this.machine.destroy();
    this.stripe1.destroy();
    this.stripe2.destroy();
    this.stripe3.destroy();
    this.musk1.destroy();
    this.musk2.destroy();
    this.musk3.destroy();
    this.text.destroy();

    delete this.container;
    delete this.machine;
    delete this.stripe1;
    delete this.stripe2;
    delete this.stripe3;
    delete this.musk1;
    delete this.musk2;
    delete this.musk3;
    delete this.text;

    // App.destroy(); // cause hot reload error
  }

  onClick = () => {
    if (this.isDrew) return;

    this.handle.play();
    this.props.onClick();
    this.animationRunning();
    this.isDrew = true;

  }

  animationRunning = async () => { // running
    const att = {
      y: 0,
      y1: 0
    };

    const anim = gsap.to(att, {
      y: 2010,
      y1: 4010,
      duration: 2010,
      ease: 'none',
      repeat: 0,
      onComplete: () => {
        if (!this.isRunning) {
          anim.kill();
          return;
        }
        switch (this.props.status) {
          case SLOT_MACHINE_RESULT.LOST:
            this.animationLosing();
            break;
          case SLOT_MACHINE_RESULT.WIN:
            this.animationWining();
            break;
          default:
            this.animationRunning();
        }

        anim.kill();
      },
      onUpdate: () => {
        if (this.isRunning) {
          this.stripe1.tilePosition.y = att.y;
          this.stripe2.tilePosition.y = att.y1;
          this.stripe3.tilePosition.y = att.y;
        } else {
          anim.kill();
        }
      }
    }).play();
  }

  animationLosing = async () => { // lost
    const att = {
      y: 0,
      y1: 0,
      y2: 0
    };

    const anim = gsap.to(att, {
      y: 1930,
      y1: 4185,
      y2: 2016,
      duration: 4000,
      ease: 'power1.out',
      repeat: 0,
      onComplete: () => {
        anim.kill();
        if (this.isRunning) {
          this.text.text = '謝謝參與';
        }
      },
      onUpdate: () => {
        if (this.isRunning) {
          this.stripe1.tilePosition.y = att.y;
          this.stripe2.tilePosition.y = att.y1;
          this.stripe3.tilePosition.y = att.y2;
        } else {
          anim.kill();
        }
      }
    }).play();
  }

  animationWining = async () => { // won
    const att = {
      y: 0,
      y1: 0,
      y2: 0
    };

    const anim = gsap.to(att, {
      y: 1850,
      y1: 4183,
      y2: 2903,
      duration: 4000,
      ease: 'power1.out',
      repeat: 0,
      onComplete: () => {
        anim.kill();
        if (this.isRunning) {
          this.text.text = '恭喜中獎';
        }
      },
      onUpdate: () => {
        if (this.isRunning) {
          this.stripe1.tilePosition.y = att.y;
          this.stripe2.tilePosition.y = att.y1;
          this.stripe3.tilePosition.y = att.y2;
        } else {
          anim.kill();
        }
      }
    }).play();
  }

  loop = () => {
    gsap.updateRoot(performance.now());
  };

  render() {
    return (
      <div
        ref={this.canvas}
        style={{ height: 350, width: 350 }}
      />
    );
  }
}

SlotMachine.propTypes = {
  onClick: PropTypes.func,
  status: PropTypes.string
};

SlotMachine.defaultProps = {
  onClick: () => {},
  status: SLOT_MACHINE_RESULT.NOT_START
};

