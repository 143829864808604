import Cookies from 'js-cookie';

import API from '../apis';
import USER_TOKEN_KEY from '../constants';

export default class SessionService {

  static async getSession(id) {
    const token = Cookies.get(USER_TOKEN_KEY);
    return API.session.getSession(token, id);
  }

  static async getSessionList(anchor, limit, search) {
    const token = Cookies.get(USER_TOKEN_KEY);
    return API.session.getSessionList(token, anchor, limit, search);
  }

  static async getMyCheckInStatus() {
    const token = Cookies.get(USER_TOKEN_KEY);
    return API.session.getMyCheckInStatus(token);
  }
}

