import { request, getHost } from './utils';

export const listSession = (token, limit, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/lottery`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${token}`
    },
    params: {
      limit,
      anchor
    }
  };
  return request(options);
};

export const listLottery = (token, id, limit, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/lottery/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${token}`
    },
    params: {
      limit,
      anchor
    }
  };
  return request(options);
};

export const listResult = (token, sid, id, limit, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/lottery/${sid}/${id}/result`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${token}`
    },
    params: {
      limit,
      anchor
    }
  };
  return request(options);
};
