import { request, getHost } from './utils';

export const getSession = async (token, id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/session/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${token}`
    }
  };
  const response = await request(options);
  return response.data;
};

export const getSessionList = async (token, anchor, limit, search) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/session`,
    params: {
      anchor, limit, search
    },
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${token}`
    }
  };
  const response = await request(options);
  return response.data;
};

export const getMyCheckInStatus = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/session/checkIn/me`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${token}`
    }
  };
  return request(options);
};
