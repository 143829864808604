import {
  makeObservable, observable, runInAction
} from 'mobx';
import SessionService from '../services/Session';

export default class CheckInViewModel {

  @observable isCheckInScreenShowed = false;

  @observable id = null;

  @observable title = null;

  @observable subtitle = null;

  @observable hasCheckedIn = null;

  @observable checkInStatus = [];

  constructor(params) {
    makeObservable(this);
    this.uid = params.uid;
  }

  didMount = () => {
    this.getMyCheckInStatus();
  }

  showCheckInScreen = (obj) => {
    runInAction(() => {
      this.isCheckInScreenShowed = true;
      this.title = obj.title;
      this.subtitle = obj.subtitle;
      this.id = obj.id;
    });
  }

  closeCheckInScreen = () => {
    runInAction(() => {
      this.isCheckInScreenShowed = false;
    });
  }

  getMyCheckInStatus = async () => {
    const res = await SessionService.getMyCheckInStatus();
    runInAction(() => {
      this.checkInStatus = res.data.data;
    });
  }

  onCheckInResult = (userId, sessionId) => {
    if (this.uid === userId) {
      this.checkInStatus.push(sessionId);
      this.closeCheckInScreen();
    }
  }
}
