import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { PixiPlugin } from 'gsap/PixiPlugin';
import { ExpoScaleEase, RoughEase } from 'gsap/EasePack';

gsap.registerPlugin(
  MotionPathPlugin,
  ExpoScaleEase,
  RoughEase,
  PixiPlugin
);

export function tween(from, config) {
  if (config.onUpdate) {
    return gsap.to(from, {
      ...config,
      onUpdate: () => config.onUpdate(from)
    });
  }
  return gsap.to(from, config);
}

export default gsap;
