import dayjs from 'dayjs';
import i18n from '../../I18n';

export default class PointRecord {
  constructor(params) {
    const {
      id,
      boothAccount,
      boothName,
      userEmail,
      point,
      date
    } = params ?? {};

    this.id = id;
    this.boothAccount = boothAccount;
    this.boothName = boothName;
    this.userEmail = userEmail;
    this.point = point;
    this.date = date;
  }

  get title() {
    if (this.boothName) {
      return this.boothName;
    }

    return this.point < 0 ? i18n.t('points_deduct') : i18n.t('points_add');
  }

  get dateInFormat() {
    if (!this.date) {
      return null;
    }

    return dayjs(this.date).format('YYYY-MM-DD HH:mm:ss');
  }

  get pointForDisplay() {
    if (this.boothName || this.boothAccount) {
      return (this.point * -1).toLocaleString();
    }

    return this.point.toLocaleString();
  }

  static fromRes(data) {
    return new PointRecord(data);
  }
}
