/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './ResultCard.module.css';

export default function AllResultCard(props) {
  const [expand, setExpand] = useState(false);

  return (
    <div className={styles.cardBox} onClick={() => setExpand(!expand)}>
      <div className={`result-card ${cn(styles.resultCard, styles.resultCard_layout)}`}>

        <div className={cn(styles.block16, styles.block16_layout)}>
          <div className={cn(styles.subtitle, styles.subtitle_layout)}>{props.title}</div>
          <h4 className={cn(styles.title, styles.title_layout)}>{props.award}</h4>
        </div>
        <div className={styles.iconBox}>
          {expand
            ? (
              <UpOutlined
                className={cn(styles.icon1, styles.icon1_layout)}
              />
            )
            : (
              <DownOutlined
                className={cn(styles.icon1, styles.icon1_layout)}
              />
            )}
        </div>

      </div>

      {expand && (
        <div className={styles.listBox}>
          {props.list.map((user) => (
            <div className={styles.listItemBox} key={user.employeeId}>
              <div>{user.name}</div>
              <div>{user.employeeId}</div>
            </div>
          ))}
        </div>
      )}

    </div>
  );
}

AllResultCard.propTypes = {
  title: PropTypes.string.isRequired,
  award: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array.isRequired
};

