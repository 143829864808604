import {
  action, computed, makeObservable, observable, runInAction
} from 'mobx';
import PubNub from 'pubnub';
import { message } from 'antd';
import PartyService from '../services/Party';
import ProgramService from '../services/Program';
import ActivityService from '../services/Activity';
import SessionService from '../services/Session';
import i18n from '../I18n';

export const SLOT_MACHINE_RESULT = {
  NOT_START: 'notStart',
  WIN: 'win',
  LOST: 'lost'
};

export default class ProgramViewModel {
  @observable announcement = [];

  @observable program = null;

  @observable currentActivity = null;

  @observable isActivityOpened = false;

  @observable orientation = 'portrait';

  @observable chatPubNub = null;

  @observable channelPubNub = null;

  @observable slotMachine = null;

  @observable isActivityModalShowed = false;

  @observable isCheckInScreenShowed = false;

  @observable sessionList = [];

  @observable result = null;

  @observable enablePoint = false;

  @observable enableChallenges = false;

  @observable isUserCheckIn = false;

  showAwardResult = true;

  showAllResult = true;

  onPrizeResult = () => {
  }

  onCheckInResult = () => {}

  onMissionCompleted = () => {}

  constructor(params) {
    makeObservable(this);
    this.uid = params.uid;
    this.tid = params.tid;
    this.isUserCheckIn = params.isUserCheckIn;
    this.enablePoint = params.enablePoint;
    this.enableChallenges = params.enableChallenges;
    this.refresh();
  }

  getUrl(url) {
    return `${window.location.href}interaction/${url}?uid=${this.uid}&tid=${this.tid}`;
  }

  @computed get hasSession() {
    return this.sessionList.length > 0;
  }

  @action
  refresh = async () => {
    const announcement = await PartyService.listAnnouncement();
    const program = await ProgramService.getProgram();
    const sessions = await SessionService.getSessionList();
    runInAction(() => {
      this.announcement = announcement;
      this.program = program;
      this.sessionList = sessions.data.filter((s) => !s.hidden);

      if (program.currentActivity?.activityType !== 'slotMachine') {
        this.currentActivity = program.currentActivity;
        this.isActivityOpened = true;
      }

      if (this.program.chat) {
        this.chatPubNub = new PubNub({
          publishKey: this.program.chat.publishKey,
          subscribeKey: this.program.chat.subscribeKey,
          uuid: this.program.chat.uuid
        });
      }

      if (this.program.channel) {
        this.channelPubNub = new PubNub({
          subscribeKey: this.program.channel.subscribeKey
        });

        this.channelPubNub.subscribe({
          channels: [this.program.channel.channel]
        });

        this.channelPubNub.addListener({
          message: this.onMessage
        });
      }

      if (program.currentActivity && program.currentActivity.directOpen) {
        window.open(this.getUrl(program.currentActivity.playerUrl), '_self');
      }
    });

    console.log(program);
  }

  @action
  onMessage = (event) => {
    const { content } = event.message;

    if (content.action === 'revoke') {
      this.currentActivity = null;
      this.slotMachine = null;
      this.isActivityOpened = false;
      return;
    }

    if (content.activityType === 'slotMachine') {
      this.currentActivity = null;
      this.isActivityOpened = true;
      this.slotMachine = {
        id: content.id,
        awardRate: content.awardRate,
        status: SLOT_MACHINE_RESULT.NOT_START,
        sessionScope: content.sessionScope ?? []
      };
      this.showModal();
      return;
    }

    if (content.type === 'checkIn') {
      this.onCheckInResult(content.userId, content.sessionId);
      this.isUserCheckIn = true;
      return;
    }

    if (content.type === 'missionCompleted') {
      this.onMissionCompleted(content.userId, content.missionNumber);
      return;
    }

    if (content.type === 'activityResult') {
      const result = {
        title: content.activity.title,
        award: content.activity.award,
        teamId: content.teamId ?? null,
        userIds: content.userIds ?? null
      };
      this.onPrizeResult(result);
      return;
    }

    if (content.type === 'lotteryResult') {
      const result = {
        title: content.title,
        award: content.award,
        userIds: content.userIds
      };
      this.onPrizeResult(result);
      return;
    }


    this.currentActivity = {
      activityType: content.activityType,
      title: content.title,
      playerUrl: content.playerUrl,
      description: content.description,
      relatedUrl: content.relatedUrl,
      directOpen: content.directOpen,
      sessionScope: content.sessionScope ?? []
    };
    this.slotMachine = null;
    this.isActivityOpened = true;

    this.showModal();

    if (content && content.directOpen) {
      window.open(this.getUrl(content.playerUrl), '_self');
    }

  };

  unsubscribeChannel = () => {
    if (this.channelPubNub) {
      this.channelPubNub.unsubscribe({
        channels: [this.program.channel.channel, this.program.chat.channel]
      });
    }
  }

  onSlotMachineClick = async () => {
    const random = Math.random();

    if (random > this.slotMachine.awardRate) {
      runInAction(() => {
        this.slotMachine = { ...this.slotMachine, status: SLOT_MACHINE_RESULT.LOST };
      });
      return;
    }


    try {
      const result = await ActivityService.drawAward(this.slotMachine?.id);

      if (result) {
        runInAction(() => {
          this.slotMachine = { ...this.slotMachine, status: SLOT_MACHINE_RESULT.WIN };
        });
      }

      if (!result) {
        runInAction(() => {
          this.slotMachine = { ...this.slotMachine, status: SLOT_MACHINE_RESULT.LOST };
        });
      }
    } catch {
      message.error(i18n.t('error'));
    }
  }

  @action
  toggleActivity = () => {
    this.isActivityOpened = !this.isActivityOpened;
  }

  @computed
  get hasChat() {
    if (this.chatPubNub) return true;
    return false;
  }

  @computed
  get hasActivityBox() {
    return (this.currentActivity || this.slotMachine) && (this.program?.status !== 'close');
  }

  @action
  onOrientationChange = (orientation) => {
    this.orientation = orientation;
  }

  @action
  handleModalCancel = () => {
    this.isActivityModalShowed = false;
  }

  @action
  showModal = () => {
    this.isActivityModalShowed = true;
  }
}
