import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  makeObservable, observable, runInAction, computed
} from 'mobx';

import styles from './styles.module.css';

@observer
class MainLayout extends React.Component {
  domain = window.location.hostname.split('.')[0];

  header = `https://storage.googleapis.com/mmparty/${this.domain}/header.jpg?r=${Math.random()}`;

  logoBig = `https://storage.googleapis.com/mmparty/${this.domain}/logo-big.png?r=${Math.random()}`;

  logoSmall = `https://storage.googleapis.com/mmparty/${this.domain}/logo-small.png?r=${Math.random()}`;

  backgroundBig = `https://storage.googleapis.com/mmparty/${this.domain}/login-background-big.jpg?r=${Math.random()}`;

  backgroundMiddle = `https://storage.googleapis.com/mmparty/${this.domain}/login-background-middle.jpg?r=${Math.random()}`;

  backgroundsmall = `https://storage.googleapis.com/mmparty/${this.domain}/login-background-small.jpg?r=${Math.random()}`;

  @observable deviceWidth = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  @computed
  get background() {
    if (this.deviceWidth > 834) return `url(${this.backgroundBig})`;
    if (this.deviceWidth > 375) return `url(${this.backgroundMiddle})`;
    return `url(${this.backgroundsmall})`;
  }

  resize = () => {
    runInAction(() => {
      this.deviceWidth = window.innerWidth;
    });
  }

  render() {
    return (
      <div className={styles.page}>

        <div style={{ backgroundImage: `url(${this.header})` }} className={styles.header}>
          <img alt="logo" src={this.logoSmall} className={styles.logoSmall} />
          <div className={styles.name}>{this.props.name}</div>
        </div>

        <div style={{ backgroundImage: this.background }} className={styles.body}>
          <div className={styles.content}>
            <div className={styles.logo}>
              <img alt="logo" src={this.logoBig} />
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired
};

export default MainLayout;
