/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import pointIcon from '../../assets/pointIcon.svg';

import styles from './PointCard.module.css';

export default function PointCard(props) {
  return (
    <div
      className={cn(styles.pointCard, styles.clickable)}
      onClick={props.onClick}
    >
      <div className={styles.pointCard_item}>
        <div className={cn(styles.text, styles.text_layout)}>
          <div className={cn(styles.title_box, styles.title_box_layout)}>
            <div className={styles.flexRow}>
              <img
                src={pointIcon}
                alt="point"
                className={styles.pointIcon}
              />
              <div className={styles.title}>
                {props.point}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pointCard_spacer} />
      <div className={styles.pointCard_item1}>
        <div className={cn(styles.icon, styles.icon_layout)}>
          <RightOutlined className={cn(styles.icon1, styles.icon1_layout)} />
        </div>
      </div>
    </div>
  );
}

PointCard.propTypes = {
  point: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

PointCard.defaultProps = {
  onClick: () => {}
};

PointCard.inStorybook = true;

