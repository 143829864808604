import React from 'react';
import { observer } from 'mobx-react';
import {
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import { message, Spin } from 'antd';
import i18n from '../../I18n';

import MeService from '../../services/Me';
import PartyService from '../../services/Party';

import NotStartedPage from './NotStartedPage';
import LoginPage from './LoginPage';
import ActivatePage from './ActivatePage';
import ProgramPage from './ProgramPage';
import ProfilePage from './ProfilePage';
import MainLayout from '../../components/MainLayout';

@observer
export default class MainPage extends React.Component {
  @observable isLoggedin = false;

  @observable isActivated = false;

  @observable party = null;

  @observable me = null;

  @observable canLogin = false;

  domain = window.location.hostname.split('.')[0];

  isLenovo = this.domain === '2023lenovoyearendparty' || this.domain === 'dev-2023lenovoyearendparty';

  constructor(props) {
    super(props);

    makeObservable(this);
    this.init();
  }

  init = async () => {
    await i18n.loadI18n();
    await this.getParty();
    this.check();
  }

  check = async () => {
    if (MeService.hasToken()) {
      try {
        const me = await MeService.profile();

        if (me.isBlock) {
          message.error(i18n.t('account_banned'));
          return;
        }

        runInAction(() => {
          this.me = me;
          this.isLoggedin = true;
          this.isActivated = me.isActivated;
        });

        if ((!this.isActivated) && (!this.party.awardList) && (!this.party.relative)) {
          await MeService.activate('', 0);
          runInAction(() => {
            this.isActivated = true;
          });
        }
      } catch (e) {
        this.onLogoutClick();
      }
    }
  }

  getParty = async () => {
    const party = await PartyService.getParty();
    runInAction(() => { this.party = party; });

    if (party.status !== 'notStarted') {
      runInAction(() => { this.canLogin = true; });

      if (party?.canUseAnonymousLogin && party?.canAutoLogin) {
        await this.onLoginClick({
          name: '自動登入使用者', department: '無'
        }, 'useAnonymous');
      }
    }
  }

  onLoginClick = async (field, type) => {
    try {
      if (type === 'useEmployeeId') {
        await MeService.loginWithEmployeeId(field.employeeId);
      } else if (type === 'useAnonymous') {
        await MeService.loginWithAnonymous(field.name, field.department);
      } else {
        await MeService.login(field.email, field.password);
      }
      this.check();

    } catch (err) {
      if (err.response.status === 401) {
        if (this.party.canUseEmployeeIdLogin) {
          message.error(i18n.t('account_error_employeeId'));
        } else {
          message.error(i18n.t('account_error'));
        }

      } else {
        message.error(i18n.t('account_not_exist'));
      }
    }
  }

  onLogoutClick = async () => {
    await MeService.logout();

    runInAction(() => {
      this.isLoggedin = false;
      this.isActivated = false;
      this.me = {};
      this.domain = '';
    });
  }

  onActivateClick = async (field) => {
    await MeService.activate(field.award, field.relativeCount);
    runInAction(() => {
      this.isActivated = true;
    });
  }

  render() {
    if (!this.party) {
      return <Spin />;
    }

    if (!this.canLogin) {
      return (
        <MainLayout name={this.party?.name} domain={this.domain}>
          <NotStartedPage />
        </MainLayout>
      );
    }

    if (!this.isLoggedin && this.party?.canAutoLogin) {
      return (
        <div>
          <Spin />
          {i18n.t('log_in_loggin')}
        </div>
      );
    }

    if (!this.isLoggedin) {
      return (
        <LoginPage
          name={this.party?.name}
          domain={this.domain}
          useEmployeeId={this.party?.canUseEmployeeIdLogin}
          useAnonymous={this.party?.canUseAnonymousLogin}
          onLoginClick={this.onLoginClick}
        />
      );
    }

    if (!this.isActivated) {
      return (
        <MainLayout name={this.party?.name} domain={this.domain}>
          <ActivatePage
            onActivateClick={this.onActivateClick}
            party={this.party}
          />
        </MainLayout>
      );
    }

    if (this.party.enableSession) {
      return (
        <ProfilePage
          party={this.party?.name}
          teamCount={this.party?.teamCount}
          uid={this.me?.id}
          tid={this.me?.teamId}
          name={this.me?.name}
          email={this.me?.email}
          department={this.me?.department}
          employeeId={this.me?.employeeId}
          teamId={this.me?.teamId}
          logout={this.onLogoutClick}
          isDrawBlock={this.me?.isDrawBlock}
          jobTitle={this.me?.jobTitle}
          nickname={this.me?.nickname}
          usedPoints={this.me?.usedPoints}
          completedMission={this.me?.completedMission}
          useEmployeeId={this.party?.canUseEmployeeIdLogin ?? false}
          isUserCheckIn={this.me?.isCheckIn}
          enableChallenges={this.party?.enableChallenges}
          enablePoint={this.party?.enablePoint}
        />
      );
    }
    return (
      <ProgramPage
        party={this.party?.name}
        teamCount={this.party?.teamCount}
        uid={this.me?.id}
        tid={this.me?.teamId}
        name={this.me?.name}
        email={this.me?.email}
        department={this.me?.department}
        employeeId={this.me?.employeeId}
        teamId={this.me?.teamId}
        logout={this.onLogoutClick}
      />
    );
  }
}
