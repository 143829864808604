import { PIXI } from '../PIXI';

class AssetsManager {
  loader = PIXI.Loader.shared

  static loadAll(list) {
    const loader = PIXI.Loader.shared;

    list.forEach((a) => {
      try {
        if (!loader.resources[a.id]) {
          loader.add(a.id, a.source);
        }
      } catch (err) {
        console.log('loader.add', err);
      }
    });

    return new Promise((resolve) => {
      loader.load((lr, resources) => {
        resolve({ loader: lr, resources });
      });
    });
  }

  static load(id, source) {
    const loader = PIXI.Loader.shared;

    if (!loader.resources[id]) {
      loader.add(id, source);

      return new Promise((resolve) => {
        loader.load((lr, resources) => {
          resolve({ loader: lr, resources });
        });
      });
    }

    return Promise.resolve(null);
  }

  /**
   * get loaded asset
   */
  static getResource(id) {
    const loader = PIXI.Loader.shared;
    return loader.resources[id];
  }

  /**
   * reset pixi loader
   */
  static reset() {
    const loader = PIXI.Loader.shared;
    loader.reset();
  }
}

export default AssetsManager;

