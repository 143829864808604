export default class Booth {
  constructor(params) {
    const {
      id,
      account,
      name,
      number
    } = params ?? {};

    this.id = id;
    this.account = account;
    this.name = name;
    this.number = number;
  }

  static fromRes(data) {
    return new Booth(data);
  }
}
