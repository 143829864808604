/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { RightOutlined, CheckOutlined } from '@ant-design/icons';

import styles from './SessionCard.module.css';

export default function SessionCard(props) {
  return (
    <div
      className={props.hasCheckedIn ? styles.sessionCard : cn(styles.sessionCard, styles.clickable)}
      onClick={props.hasCheckedIn ? null : props.showCheckInScreen}
    >
      <div className={styles.sessionCard_item}>
        <div className={cn(styles.text, styles.text_layout)}>
          <div className={cn(styles.subtitle, styles.subtitle_layout)}>{props.subtitle}</div>
          <div className={cn(styles.title_box, styles.title_box_layout)}>
            <div className={styles.title}>{props.title}</div>
          </div>
        </div>
      </div>
      <div className={styles.sessionCard_spacer} />
      <div className={styles.sessionCard_item1}>
        <div className={cn(styles.icon, styles.icon_layout)}>
          {props.hasCheckedIn
            ? <CheckOutlined className={cn(styles.icon1, styles.icon1_layout)} />
            : <RightOutlined className={cn(styles.icon1, styles.icon1_layout)} />}
        </div>
      </div>
    </div>
  );
}

SessionCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  showCheckInScreen: PropTypes.func.isRequired,
  hasCheckedIn: PropTypes.bool.isRequired
};

SessionCard.defaultProps = {
  subtitle: null
};

SessionCard.inStorybook = true;

