/* eslint-disable lines-between-class-members */
import {
  makeObservable, observable, runInAction, action, computed
} from 'mobx';
import { message } from 'antd';
import dayjs from 'dayjs';
import i18n from '../I18n';
import MeService from '../services/Me';
import BoothService from '../services/Booth';
import { parseQuery } from '../utils/common';

message.config({
  top: '80%'
});

export const TRANSFER_RESULT = {
  Success: 'success',
  Fail: 'fail'
};

export default class PointsViewModel {
  @observable isScreenShow = false;

  @observable isBoothDrawerShown = false;
  @observable isTransferDrawerShown = false;
  @observable isConfirmTransferModalShown = false;

  @observable boothNum = null;
  @observable boothName = null;
  @observable boothNumError = null;

  @observable currentPoint = 0;
  @observable usedPoint = 0;

  @observable updatedAt = '';

  @observable transactionList = [];
  @observable anchor = null;

  @observable result = null;

  @observable amount = '';

  @observable isLoading = {
    profile: false,
    transaction: false,
    transfer: false
  };

  constructor(params) {
    makeObservable(this);

    runInAction(() => {
      this.uid = params.uid;
    });
  }

  didMount = async () => {
    await this.getTransaction();
  }

  @action
  getTransaction = async (shouldReset = true) => {
    if (shouldReset) {
      this.isLoading.transaction = true;
      this.transactionList = [];
    }

    try {
      const res = await MeService.transaction(this.anchor);

      runInAction(() => {
        this.transactionList.push(...res.results.data);
        this.anchor = res.results.anchor;
        this.currentPoint = res.currentPoint;
        this.usedPoint = res.usedPoint;
        this.updatedAt = dayjs().format('YYYY-MM-DD HH:mm');
      });
    } catch (err) {
      console.log(err);
    } finally {
      runInAction(() => {
        this.isLoading.transaction = false;
      });
    }
  }

  @action onChangeBoothNum = (e) => {
    this.boothNum = e.target.value.toUpperCase();

    if (this.boothNumError) {
      this.boothNumError = null;
    }
  }

  @action getBoothInfo = async () => {
    if (!this.boothNum) {
      this.boothNumError = i18n.t('booth_id_error_empty');
      return;
    }

    try {
      const res = await BoothService.profile(this.boothNum);
      this.boothName = res.name;
      this.closeBoothDrawer();
      this.showTransferDrawer();
    } catch (err) {
      console.log(err);
      const { boothNum } = parseQuery();
      if (boothNum) {
        message.error(i18n.t('message_booth_not_found'));
        window.history.replaceState(null, '', window.location.pathname);
        runInAction(() => {
          this.boothNum = null;
        });
      } else {
        this.boothNumError = i18n.t('booth_id_error_incorrect');
      }
    }
  }

  @action onChangeAmount = (v) => {
    if (v === 'back') {
      this.amount = this.amount.slice(0, this.amount.length - 1);
    } else {
      this.amount += v;
    }
  }

  @computed get isTransferDisabled() {
    const amount = parseInt(this.amount, 10);
    return !amount;
  }


  @action onTransfer = async () => {
    console.log(this.amount);
    this.isLoading.transfer = true;

    const closeDrawer = () => {
      this.closeTransferDrawer();
      runInAction(() => {
        this.isLoading.transfer = false;
      });
    };

    try {
      await BoothService.pay(this.boothNum, this.amount);
      closeDrawer();
      this.result = TRANSFER_RESULT.Success;
      await this.getTransaction();
    } catch (err) {
      if (err?.response?.status === 402) {
        message.error(i18n.t('message_insufficient_points'));
        runInAction(() => {
          this.isLoading.transfer = false;
        });
      } else {
        closeDrawer();
        this.result = TRANSFER_RESULT.Fail;
      }
    }
  }

  @action clearResult = () => {
    this.result = null;
    this.boothNum = null;
    this.boothName = null;
    this.amount = '';
    window.history.replaceState(null, '', window.location.pathname);
  }

  onScrollList = async (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5;
    if (bottom && this.anchor) {
      console.log('hit the bottom!');
      await this.getTransaction(false);
    }
  }

  // ///////////////////////////////////////////////

  @action showScreen = async () => {
    this.isScreenShow = true;
    await this.getTransaction();
  }

  @action closeScreen = () => {
    this.isScreenShow = false;
    if (window.location.hash) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }

  @action showBoothDrawer = () => {
    this.isBoothDrawerShown = true;
  }

  @action closeBoothDrawer = () => {
    this.isBoothDrawerShown = false;
  }

  @action showTransferDrawer = () => {
    this.isTransferDrawerShown = true;
  }

  @action closeTransferDrawer = () => {
    this.isTransferDrawerShown = false;
  }

  @action showConfirmTransferModal = () => {
    this.isConfirmTransferModalShown = true;
  }

  @action closeConfirmTransferModal = () => {
    this.isConfirmTransferModalShown = false;
  }
}
