/* eslint-disable max-len */
/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Tabs, message } from 'antd';
import i18n from '../../../I18n';
import SessionCard from '../../../components/SessionCard';
import ResultCard from '../../../components/ResultCard';
import PointCard from '../../../components/PointCard';
import AllResultCard from '../../../components/AllResultCard';
import ClientLayout from '../../../components/ClientLayout';
import SideBar from '../ProgramPage/components/SideBar';
import CheckInScreen from '../../../components/CheckInScreen';
import PointsScreen from '../../../components/PointsScreen';
import CollectionButton from '../../../components/CollectionButton';
import CollectionScreen from '../../../components/CollectionScreen';
import userInfoStyles from './ProfilePage_userInfo.module.css';
import sessionsStyles from './ProfilePage_sessions.module.css';
import resultsStyles from './ProfilePage_results.module.css';
import styles from './ProfilePage.module.css';

import ProgramViewModel from '../../../viewModels/ProgramViewModel';
import CheckInViewModel from '../../../viewModels/CheckInViewModel';
import SideBarViewModel from '../../../viewModels/SideBarViewModel';
import PointsViewModel from '../../../viewModels/PointsViewModel';
import { parseQuery } from '../../../utils/common';
import CollectionViewModel from '../../../viewModels/CollectionViewModel';
import AnnouncementScreen from '../../../components/AnnouncementScreen';
import AnnouncementViewModel from '../../../viewModels/AnnouncementViewModel';

@observer
export default class ProfilePage extends React.Component {
  // Toggle all result tab

  constructor(props) {
    super(props);
    this.props = props;

    this.announcementViewModel = new AnnouncementViewModel();
    this.checkInViewModel = new CheckInViewModel({ uid: this.props.uid });
    this.sideBarViewModel = new SideBarViewModel({
      uid: this.props.uid,
      tid: this.props.tid
    });
    this.collectionViewModel = new CollectionViewModel({
      uid: this.props.uid,
      usedPoints: this.props.usedPoints,
      completedMission: this.props.completedMission
    });

    this.viewModel = new ProgramViewModel({
      uid: this.props.uid,
      tid: this.props.tid,
      isUserCheckIn: this.props.isUserCheckIn,
      enablePoint: this.props.enablePoint,
      enableChallenges: this.props.enableChallenges
    });

    this.pointsViewModel = new PointsViewModel({
      uid: this.props.uid
    });

    if (this.viewModel.isUserCheckIn && window.location.hash === '#points') {
      this.pointsViewModel.showScreen();
    }

    const { boothNum } = parseQuery();
    if (boothNum) {
      if (this.viewModel.isUserCheckIn) {
        this.pointsViewModel.showScreen();
        this.pointsViewModel.boothNum = boothNum;
        this.pointsViewModel.getBoothInfo();
      } else {
        message.info(i18n.t('message_cant_use_point_until_checkin'));
        window.history.replaceState(null, '', window.location.pathname);
      }
    }
  }

  componentDidMount() {
    this.viewModel.onPrizeResult = this.sideBarViewModel.onPrizeResult;
    this.viewModel.onCheckInResult = this.checkInViewModel.onCheckInResult;
    this.viewModel.onMissionCompleted = this.collectionViewModel.onMissionCompleted;

    this.checkInViewModel.didMount();
    this.sideBarViewModel.getMyAwardResult();
    this.sideBarViewModel.getAllAwardResult();
  }

  componentWillUnmount() {
    this.viewModel.onPrizeResult = () => {};
    this.viewModel.onCheckInResult = () => {};
    this.viewModel.onMissionCompleted = () => {};
  }

  _renderUserInfo() {
    return (
      <div className={userInfoStyles.userInfo}>
        <div className={cn(userInfoStyles.title, userInfoStyles.title_layout)}>
          <h1 className={cn(userInfoStyles.name, userInfoStyles.name_layout)}>{this.props.name}</h1>
          <div className={userInfoStyles.title_item}>
            <div
              className={cn(userInfoStyles.logoutButton, userInfoStyles.logoutButton_layout)}
              onClick={this.props.logout}
            >
              <px-posize
                track-style='{"flexGrow":1}'
                x="20fr minmax(0px, max-content) 18fr"
                y="8fr 16px 10fr"
                md-x="20fr minmax(0px, max-content) 20fr"
                md-y="10fr 16px 10fr"
              >
                <h5 className={userInfoStyles.text}>
                  {i18n.t('log_out')}
                </h5>
              </px-posize>
            </div>
          </div>
        </div>


        <div className={cn(userInfoStyles.subInfo, userInfoStyles.subInfo_layout)}>
          {this.props.nickname && <h5 className={userInfoStyles.subInfoText}>{this.props.nickname}</h5>}
          {/* hide user email when use EmployeeIdLogin */}
          {!this.props.useEmployeeId && <h5 className={userInfoStyles.subInfoText}>{this.props.email}</h5>}
          {this.props.employeeId && <h5 className={userInfoStyles.subInfoText}>{this.props.employeeId}</h5>}
          {this.props.department && <h5 className={userInfoStyles.subInfoText}>{this.props.department}</h5>}
          {this.props.jobTitle && <h5 className={userInfoStyles.subInfoText}>{this.props.jobTitle}</h5>}
        </div>
      </div>
    );
  }

  _renderAnnouncement() {
    return (
      <>
        <div className={styles.main_item}>
          <h5 className={cn(styles.checkInTitle, styles.checkInTitle_layout)}>活動資訊</h5>
        </div>
        {
          this.viewModel.announcement.map((e) => (
            <CollectionButton
              title={e.title}
              onClick={() => {
                if (e.type === 'link') {
                  window.open(e.content, '_blank');
                } else {
                  this.announcementViewModel.showScreen(e);
                }
              }}
            />
          ))
         }
      </>
    );
  }

  _renderSessions() {
    const sessions = this.viewModel.sessionList;

    return (
      <div className={cn(sessionsStyles.sessionCards, sessionsStyles.sessionCards_layout)}>
        {sessions.length !== 0
          ? (
            sessions.map((s) => (
              <SessionCard
                key={s.id}
                title={s.title}
                subtitle={s.subtitle ?? null}
                userId={this.props.uid}
                hasCheckedIn={this.checkInViewModel.checkInStatus.includes(s.id)}
                showCheckInScreen={() => this.checkInViewModel.showCheckInScreen(s)}
              />
            ))
          )
          : (
            <h4 className={styles.resultsTitle}>{i18n.t('no_data_sessions')}</h4>
          )}
      </div>
    );
  }

  _renderMyResults() {
    const results = this.sideBarViewModel.myListWithTitle;
    return (
      <div className={cn(resultsStyles.resultCards, resultsStyles.resultCards_layout)}>
        {results.length !== 0
          ? (
            results.map((result, index) => (
              <ResultCard
                // eslint-disable-next-line react/no-array-index-key
                key={`${result.title}_${index}`}
                title={result.title}
                award={result.award}
              />
            ))
          )
          : (
            <h4 className={cn(styles.resultsTitle, styles.noAwardsTitle)}>{i18n.t('no_data_awards')}</h4>
          )}
      </div>
    );
  }

  _renderAllResults() {
    const results = this.sideBarViewModel.awardList;
    return (
      <div className={cn(resultsStyles.resultCards, resultsStyles.resultCards_layout)}>
        {results.length !== 0
          ? (
            results.map((result) => (
              <AllResultCard
                key={`${result.id}`}
                title={result.title}
                award={result.award}
                list={result.list}
              />
            ))
          )
          : (
            <h4 className={cn(styles.resultsTitle, styles.noAwardsTitle)}>{i18n.t('no_data_awards')}</h4>
          )}
      </div>
    );
  }

  _renderCollectionButton() {
    return (
      <>
        <div className={styles.main_item}>
          <h5 className={cn(styles.checkInTitle, styles.checkInTitle_layout)}>Challenges</h5>
        </div>
        <CollectionButton onClick={this.collectionViewModel.showScreen} />
      </>
    );
  }

  render() {
    return (
      <>
        <px-posize
          alt-class={`profile-page ${styles.block}`}
          track-style='{"overflow":"auto"}'
          x="0px 1440fr 0px"
          y="0px minmax(0px,1024px) 0px"
        >
          <div className={styles.block}>
            <div className={cn(styles.flex, styles.flex_layout)}>
              <div className={styles.flex_item}>
                <px-posize
                  track-style='{"flexGrow":1}'
                  x="0px 706fr 0px"
                  y="0fr minmax(0px, 1fr) 0fr"
                  lg-x="0px 706fr 0px"
                  lg-y="0fr minmax(0px, 1fr) 0fr"
                  xxs-x="0px 706fr 0px"
                  xxs-y="0fr minmax(0px, 1fr) 0fr"
                >
                  <div className={styles.layout}>
                    <ClientLayout
                      partyTitle={this.props.party}
                    />
                  </div>
                </px-posize>
              </div>
              <div className={styles.flex_spacer} />
              <div className={styles.flex_item1} style={{ minHeight: 500 }}>
                <div className={cn(styles.container, styles.container_layout)} id="profileContainer">
                  <div className={cn(styles.main, styles.main_layout)}>

                    {this._renderUserInfo()}
                    {this._renderAnnouncement()}
                    {this.viewModel.hasSession && (
                      <>
                        <div className={styles.main_item}>
                          <h5 className={cn(styles.checkInTitle, styles.checkInTitle_layout)}>Check-in</h5>
                        </div>
                        {this._renderSessions()}
                      </>
                    )}

                    {this.viewModel.enableChallenges && this._renderCollectionButton()}
                    {this.viewModel.enablePoint && this.viewModel.isUserCheckIn && (
                      <>
                        <div className={styles.main_item}>
                          <h5 className={cn(styles.checkInTitle, styles.checkInTitle_layout)}>{i18n.t('profile_points_title')}</h5>
                        </div>
                        <PointCard
                          point={this.pointsViewModel.currentPoint.toLocaleString() ?? 0}
                          onClick={this.pointsViewModel.showScreen}
                        />
                      </>
                    )}


                    {this.viewModel.showAwardResult && (
                      <>
                        {
                          !this.viewModel.showAllResult
                            ? (
                              <>
                                <div className={styles.main_item}>
                                  <h5 className={cn(styles.resultsTitle, styles.resultsTitle_layout)}>Results</h5>
                                </div>
                                {this._renderMyResults()}
                              </>
                            )
                            : (
                              <Tabs
                                centered
                                id="profile-page-tabs"
                                className={styles.tabs}
                                onTabClick={(key) => {
                                  if (key === 'allResults') {
                                    this.sideBarViewModel.getAllAwardResult();
                                  }
                                }}
                              >
                                <Tabs.TabPane key="myResults" tab={i18n.t('award_my')} style={{ padding: 0 }}>
                                  <div style={{ marginTop: '-10px', padding: 0 }}>
                                    {this._renderMyResults()}
                                  </div>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="allResults" tab={i18n.t('award_list')} style={{ padding: 0 }}>
                                  <div style={{ marginTop: '-10px' }}>
                                    {this._renderAllResults()}
                                  </div>
                                </Tabs.TabPane>
                              </Tabs>
                            )
                        }
                      </>
                    )}
                  </div>
                </div>
                <AnnouncementScreen
                  isShown={this.announcementViewModel.isShown}
                  onClose={this.announcementViewModel.closeScreen}
                  type={this.announcementViewModel.type}
                  title={this.announcementViewModel.title}
                  content={this.announcementViewModel.content}
                />
                <CheckInScreen
                  sessionId={this.checkInViewModel.id}
                  title={this.checkInViewModel.title}
                  subtitle={this.checkInViewModel.subtitle}
                  isShown={this.checkInViewModel.isCheckInScreenShowed}
                  onClose={this.checkInViewModel.closeCheckInScreen}
                  name={this.props.name}
                  nickname={this.props.nickname}
                />
                <PointsScreen
                  vm={this.pointsViewModel}
                />
                <CollectionScreen
                  vm={this.collectionViewModel}
                />
              </div>
            </div>
          </div>
        </px-posize>
        <SideBar
          sessionIsEnabled
          viewModel={this.viewModel}
          myCheckedInSessions={this.checkInViewModel.checkInStatus}
          isDrawBlock={this.props.isDrawBlock}
          uid={this.props.uid}
          tid={this.props.tid}
          name={this.props.name}
          email={this.props.email}
          department={this.props.department}
          employeeId={this.props.employeeId}
          teamId={this.props.tid}
          logout={this.props.logout}
        />
      </>
    );
  }
}

ProfilePage.propTypes = {
  party: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  tid: PropTypes.number.isRequired,
  employeeId: PropTypes.string,
  department: PropTypes.string,
  logout: PropTypes.func.isRequired,
  isDrawBlock: PropTypes.bool.isRequired,
  jobTitle: PropTypes.string,
  nickname: PropTypes.string,
  completedMission: PropTypes.arrayOf(PropTypes.number),
  usedPoints: PropTypes.number,
  useEmployeeId: PropTypes.bool.isRequired,
  isUserCheckIn: PropTypes.bool.isRequired,
  enableChallenges: PropTypes.bool.isRequired,
  enablePoint: PropTypes.bool.isRequired
};

ProfilePage.defaultProps = {
  employeeId: '',
  department: '',
  jobTitle: '',
  nickname: '',
  completedMission: [],
  usedPoints: 0
};

ProfilePage.inStorybook = true;
