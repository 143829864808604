import Cookies from 'js-cookie';

import API from '../apis';
import USER_TOKEN_KEY from '../constants';

export default class Activity {

  static async drawAward(id) {
    const token = Cookies.get(USER_TOKEN_KEY);
    const res = await API.activity.drawAward(token, id);

    return res?.data?.success;
  }
}
