import * as me from './me';
import * as program from './program';
import * as party from './party';
import * as lottery from './lottery';
import * as activity from './activity';
import * as session from './session';
import * as booth from './booth';

export default {
  me,
  program,
  party,
  lottery,
  activity,
  session,
  booth
};
