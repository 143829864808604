import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  makeObservable, observable, action
} from 'mobx';
import {
  Tabs, Radio, Collapse, message
} from 'antd';
import { RedoOutlined } from '@ant-design/icons';

import i18n from '../../../../I18n';
import SideBardViewModel from '../../../../viewModels/SideBarViewModel';

import styles from '../program.module.css';

@observer
export default class Award extends React.Component {
  @observable currentTab = '1';

  showAwardResult = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.viewModel = this.props.viewModel;
  }

  tabBar = () => (
    <Radio.Group
      onChange={this.onTabChange}
      style={{ display: 'flex', margin: 12 }}
      defaultValue="1"
      buttonStyle="solid"
    >
      <Radio.Button value="1" style={{ flex: 1, textAlign: 'center' }}>{i18n.t('award_list')}</Radio.Button>
      <Radio.Button value="2" style={{ flex: 1, textAlign: 'center' }}>{i18n.t('award_my')}</Radio.Button>
    </Radio.Group>
  )

  @action
  onTabChange = (e) => {
    const { value } = e.target;
    this.currentTab = value;

    if (value === '1') this.viewModel.getAllAwardResult();
    if (value === '2') this.viewModel.getMyAwardResult();
  }

  render() {
    return (
      <Tabs
        defaultActiveKey={1}
        centered
        activeKey={this.currentTab}
        style={{ flexGrow: 1, height: '100%', backgroundColor: '#F1F3F7' }}
        size="small"
        renderTabBar={this.tabBar}
      >
        {
          this.showAwardResult && (
          <Tabs.TabPane
            tab={i18n.t('award_list')}
            key="1"
            style={{ height: '100%' }}
          >
            <div className={styles.award}>
              {
                this.viewModel.awardList.length ? (
                  <Collapse
                    bordered={false}
                    accordion
                    destroyInactivePanel
                  >
                    {
                      this.viewModel.awardList.map(
                        (award) => (
                          <Collapse.Panel
                            header={award.title}
                            key={`${award.id}-${award.sid}`}
                          >
                            <div className={styles.award_item}>
                              <div className={styles.award_col}>{i18n.t('winner')}</div>
                              <div className={styles.award_col}>{i18n.t('employee_id')}</div>
                            </div>

                            {award?.list?.map((detail) => (
                              <div className={styles.award_row} key={detail.employeeId}>
                                <div className={styles.award_col}>{detail.name}</div>
                                <div className={styles.award_col}>{detail.employeeId}</div>
                              </div>
                            ))}
                          </Collapse.Panel>
                        )
                      )
                    }
                  </Collapse>
                ) : (
                  <div className={styles.award_no_item}>{i18n.t('no_data_awards')}</div>
                )
              }

              <RedoOutlined
                className={styles.award_reload}
                onClick={() => {
                  this.viewModel.getAllAwardResult();
                  message.success(i18n.t('message_updated'));
                }}
              />
            </div>
          </Tabs.TabPane>
          )
        }

        <Tabs.TabPane
          tab={i18n.t('award_my')}
          key="2"
          style={{ height: '100%' }}
        >
          <div className={styles.award}>
            <div className={styles.award_title}>{i18n.t('awards')}</div>

            {this.viewModel.myList.length
              ? (
                this.viewModel.myList.map((award, index) => (
                  <div key={`${award + index}`} className={styles.award_item}>{award}</div>
                ))
              )
              : (
                <div className={styles.award_no_item}>{i18n.t('no_data_awards')}</div>
              )}

            <RedoOutlined
              className={styles.award_reload}
              onClick={() => {
                this.viewModel.getMyAwardResult();
                message.success(i18n.t('message_updated'));
              }}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

Award.propTypes = {
  viewModel: PropTypes.instanceOf(SideBardViewModel).isRequired
};
