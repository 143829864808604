import React from 'react';
import { observer } from 'mobx-react';
import i18n from '../../../I18n';

import styles from '../../../common.module.css';

@observer
class NotStarted extends React.Component {

  render() {
    return (
      <>
        <div>
          <div className={styles.title}>{i18n.t('not_started')}</div>
        </div>
      </>
    );
  }
}


export default NotStarted;
