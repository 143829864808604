/* eslint-disable lines-between-class-members */
import {
  makeObservable, observable, runInAction, action, computed
} from 'mobx';
import MeService from '../services/Me';

export default class CollectionViewModel {

  @observable isScreenShow = false;
  @observable isMissionShow = false;
  @observable isRedeemShow = false;
  @observable isConfirmShow = false;
  @observable isWarningShow = false;

  @observable usedPoints = 0;
  @observable deduction = 0;

  @observable currentMission = null;
  @observable completedMission = [];

  constructor(params) {
    makeObservable(this);

    runInAction(() => {
      this.uid = params.uid;
      this.usedPoints = params.usedPoints;
      this.completedMission = params.completedMission;
    });
  }

  didMount = () => {}

  @computed get currentPoints() {
    return this.completedMission.length - this.usedPoints;
  }

  @action onMissionCompleted = (userId, missionNumber) => {
    if (userId === this.uid) {
      this.completedMission.push(missionNumber);
      this.closeMission();
    }
  }

  @action showScreen = () => {
    this.isScreenShow = true;
  }

  @action closeScreen = () => {
    this.isScreenShow = false;
  }

  @action showMission = (missionNumber) => {
    if (this.completedMission.includes(missionNumber)) return;

    this.isMissionShow = true;
    this.currentMission = missionNumber;
  }

  @action closeMission = () => {
    this.isMissionShow = false;
    this.currentMission = null;
  }

  @action showRedeem = () => {
    this.isRedeemShow = true;
    this.deduction = 0;
  }

  @action closeRedeem = () => {
    this.isRedeemShow = false;
    this.deduction = 0;
  }

  @action onDeductionChange = (e) => {
    this.deduction = e.target.value;
  }

  @action toRedeem = () => {
    if (this.deduction === 0) return;

    if ((this.usedPoints + this.deduction) > this.completedMission.length) {
      this.isRedeemShow = false;
      this.isWarningShow = true;
      return;
    }

    this.isRedeemShow = false;
    this.isConfirmShow = true;
  }

  confirmRedeem = async () => {
    try {
      await MeService.redeem(this.deduction);

      runInAction(() => {
        this.isConfirmShow = false;
        this.usedPoints += this.deduction;
        this.deduction = 0;
      });

    } catch {
      runInAction(() => {
        this.deduction = 0;
        this.isWarningShow = true;
        this.isConfirmShow = false;
      });
    }

  }

  @action cancelRedeem = () => {
    this.isConfirmShow = false;
    this.deduction = 0;
  }

  @action closeWarning = () => {
    this.isWarningShow = false;
  }
}
