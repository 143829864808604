import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Avatar from 'antd/es/avatar';
import { PubNubProvider } from 'pubnub-react';
import {
  Chat, MessageList, MessageInput
} from '@pubnub/react-chat-components';

import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import i18n from '../../../../I18n';
import ProgramViewModel from '../../../../viewModels/ProgramViewModel';

import styles from '../program.module.css';


@observer
export default class ChatRoom extends React.Component {

  constructor(props) {
    super(props);
    this.viewModel = this.props.viewModel;
    this.state = {
      draft: '',
      key: Date.now().toString()
    };
  }

  componentWillUnmount() {
    this.viewModel.unsubscribeChannel();
    this.setState({
      draft: '',
      key: ''
    });
  }

  getUrl(url) {
    return `${window.location.href}interaction/${url}?uid=${this.props.uid}`;
  }

  render() {
    return (
      <div className={styles.chatRoom}>
        {this.viewModel.hasChat && (
          <div className={styles.side_chat}>
            <PubNubProvider client={this.viewModel.chatPubNub}>
              <Chat
                currentChannel={this.viewModel.program.chat.channel}
                theme="light"
              >
                <div className={styles.side_chat_list}>
                  <MessageList
                    fetchMessages={10}
                    avatarRenderer={
                      (props) => <Avatar>{props.user.name.substring(0, 1)}</Avatar>
                    }
                  />
                </div>
                {(this.viewModel.program?.status !== 'close') && (
                  <div>
                    <MessageInput
                      key={this.state.key}
                      placeholder={i18n.t('input_message')}
                      sendButton={i18n.t('send')}
                      onChange={(e) => {
                        this.setState({ draft: e });
                      }}
                      onSend={() => {
                        this.setState({ draft: '', key: Date.now().toString() });
                      }}
                      emojiPicker={
                        (window.innerWidth >= 768) && (
                          <Picker
                            onClick={(emoji) => {
                              this.setState((prev) => ({ draft: prev.draft + emoji.native }));
                            }}
                            i18n={{
                              search: '搜尋',
                              categories: {
                                search: '搜尋結果',
                                recent: '常用',
                                smileys: '笑臉',
                                people: '人物',
                                nature: '自然',
                                foods: '食物',
                                activity: '活動',
                                places: '旅行',
                                objects: '物件',
                                symbols: '符號',
                                flags: '旗幟',
                                custom: '其他'
                              }
                            }}
                            sheetSize={32}
                            style={{
                              width: 270,
                              height: 270,
                              overflow: 'hidden',
                              marginBottom: 37
                            }}
                          />
                        )
                      }
                    />
                  </div>
                )}
              </Chat>
            </PubNubProvider>
          </div>
        )}
      </div>
    );
  }
}

ChatRoom.propTypes = {
  viewModel: PropTypes.instanceOf(ProgramViewModel).isRequired,
  uid: PropTypes.string.isRequired
};
