import {
  makeObservable, observable, runInAction
} from 'mobx';
import SessionService from '../services/Session';

export default class AnnouncementViewModel {

  @observable isShown = false;

  @observable title = null;

  @observable content = null;

  @observable type = null;

  constructor(params) {
    makeObservable(this);
  }

  showScreen = (obj) => {
    runInAction(() => {
      this.isShown = true;
      this.title = obj.title;
      this.type = obj.type;
      this.content = obj.content;
    });
  }

  closeScreen = () => {
    runInAction(() => {
      this.isShown = false;
    });
  }
}
