/* eslint-disable max-len */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { Radio, Drawer, Modal } from 'antd';
import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';
import USER_TOKEN_KEY from '../../constants';
import i18n from '../../I18n';

import styles from './CollectionScreen.module.css';

@observer
export default class CollectionScreen extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Drawer
        visible={this.props.vm.isScreenShow}
        width="min(550px, 100%)"
        closable={false}
        bodyStyle={{
          backgroundColor: '#F7F7F7',
          display: 'flex',
          flexDirection: 'column',
          rowGap: 16
        }}
      >
        <CloseOutlined className={styles.closeIcon} onClick={this.props.vm.closeScreen} />

        <div className={styles.main}>
          <div className={styles.state_container}>
            <div className={styles.state}>
              <div className={styles.state_title}>{i18n.t('mission_currently')}</div>
              <div className={styles.state_number}>{this.props.vm.currentPoints}</div>
              <div className={styles.state_points}>{i18n.t('mission_points')}</div>
            </div>
            <div className={styles.state}>
              <div className={styles.state_title}>{i18n.t('mission_used')}</div>
              <div className={styles.state_number}>{this.props.vm.usedPoints}</div>
              <div className={styles.state_points}>{i18n.t('mission_points')}</div>
            </div>
          </div>
          <div className={styles.mission_container_wrapper}>
            <div className={styles.mission_container}>
              {[1, 2, 3].map((index) => (
                <div
                  key={index}
                  className={styles.mission}
                  onClick={() => this.props.vm.showMission(index)}
                  style={this.props.vm.completedMission.includes(index) ? { backgroundColor: '#1890FF' } : null}
                >
                  <div className={styles.mission_title}>{i18n.t('mission')}</div>
                  <div className={styles.mission_number}>{index}</div>
                </div>
              ))}
            </div>

          </div>
          <div className={styles.redeem_container}>
            <div
              className={styles.btn_primary}
              onClick={this.props.vm.showRedeem}
            >
              {i18n.t('mission_redeem')}
            </div>
          </div>
        </div>

        <Modal
          visible={this.props.vm.isRedeemShow}
          onCancel={this.props.vm.closeRedeem}
          zIndex={1000}
          footer={null}
          maskClosable={false}
          centered
        >
          <Radio.Group
            onChange={this.props.vm.onDeductionChange}
            value={this.props.vm.deduction}
          >
            <div className={styles.modal_redeem_opt_container}>
              <Radio value={1} className={styles.modal_redeem_opt}>{i18n.t('mission_opt_1')}</Radio>
              <Radio value={2} className={styles.modal_redeem_opt}>{i18n.t('mission_opt_2')}</Radio>
              <Radio value={3} className={styles.modal_redeem_opt}>{i18n.t('mission_opt_3')}</Radio>
            </div>
          </Radio.Group>
          <div
            className={styles.btn_primary}
            onClick={this.props.vm.toRedeem}
          >
            <div>{i18n.t('mission_redeem')}</div>
            <div>{`(${i18n.t('mission_staff_only')})`}</div>
          </div>
        </Modal>

        <Modal
          visible={this.props.vm.isConfirmShow}
          onCancel={this.props.vm.cancelRedeem}
          zIndex={1000}
          footer={null}
          maskClosable={false}
          centered
        >
          <div className={styles.modal_confirm_txt}>
            {i18n.t('mission_confirm')}
          </div>
          <div className={styles.modal_confirm_row}>
            <div
              className={styles.btm_secondary}
              onClick={this.props.vm.cancelRedeem}
            >
              {i18n.t('no')}
            </div>
            <div
              className={styles.btn_primary}
              onClick={this.props.vm.confirmRedeem}
            >
              {i18n.t('yes')}
            </div>
          </div>
        </Modal>

        <Modal
          visible={this.props.vm.isWarningShow}
          onCancel={this.props.vm.closeWarning}
          zIndex={1000}
          footer={null}
          maskClosable={false}
          centered
          bodyStyle={{ padding: '32px 24px', fontSize: 16 }}
        >
          {i18n.t('mission_redeem_error')}
        </Modal>

        <div
          className={styles.musk}
          style={this.props.vm.isMissionShow ? { zIndex: 100 } : { zIndex: -100 }}
        >
          <div
            className={styles.pop}
            style={this.props.vm.isMissionShow ? { transform: 'translateY(0)' } : null}
          >
            <div className={styles.pop_close_btn}>
              <CloseOutlined onClick={this.props.vm.closeMission} />
            </div>
            <div className={styles.pop_main}>
              <div className={styles.pop_title}>
                {`${i18n.t('mission')} ${this.props.vm.currentMission ?? ''}`}
              </div>
              <QRCode
                value={`${window.location && window.location.href}api/v1/users/completeMission/${Cookies.get(USER_TOKEN_KEY)}/${this.props.vm.currentMission}`}
                size={210}
              />
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}

CollectionScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  vm: PropTypes.object.isRequired
};

CollectionScreen.defaultProps = {

};
