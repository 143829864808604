import * as PIXI from 'pixi.js';

window.PIXI = PIXI;

/**
 * resolution:
 *   pixel5 - 2.75
 *   mac pro m1 - 2
 */

const App = new PIXI.Application({
  backgroundColor: 0x000000,
  backgroundAlpha: 0,
  resolution: window.devicePixelRatio || 1,
  autoDensity: true,
  antialias: true
});


const PixiModule = {
  PIXI,
  App
};

export default PixiModule;
export {
  PIXI,
  App
};
