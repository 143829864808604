import {
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import axios from 'axios';
import MeService from '../services/Me';
import LotteryService from '../services/Lottery';

const PAGE_SIZE = null;

export default class SideBarViewModel {
  @observable myList = [];

  @observable myListWithTitle = [];

  @observable awardList = [];

  @observable uid = null;

  @observable tid = null;

  constructor(params) {
    makeObservable(this);
    runInAction(() => {
      this.uid = params?.uid;
      this.tid = params?.tid;
    });
  }

  onTabClick = async (key) => {
    if (key === '2') {
      this.getMyAwardResult();
      this.getAllAwardResult();
    }
  }

  getMyAwardResult = async () => {
    const newList = [];
    const newListWithTitle = [];

    const res = await MeService.getAward();
    const { activities, lotteries } = res;

    activities.forEach((activity) => {
      newList.push(activity.title);
      newListWithTitle.push({ title: activity.title, award: activity.award });
    });

    lotteries.forEach((lottery) => {
      newList.push(lottery.title);
      newListWithTitle.push({ title: lottery.title, award: lottery.award });
    });

    runInAction(() => {
      this.myList = newList;
      this.myListWithTitle = newListWithTitle;
    });
  }

  getAwardList = async () => {
    const result = [];
    const sessions = await LotteryService.listSession(PAGE_SIZE, null);

    const slist = await Promise.all(sessions.data.map(async (session) => {
      const lotterys = await LotteryService.listLottery(session.id, PAGE_SIZE, null);
      return {
        data: lotterys.data
      };
    }));

    slist.forEach((s) => {
      s.data.forEach((lottery) => {
        if (lottery.status === 'close') {
          result.push({ ...lottery });
        }
      });
    });

    runInAction(() => { this.awardList = result; });
  }

  getAllAwardResult = async () => {
    // fetch lottery index json file
    const partyId = window.location.hostname.split('.')[0];
    const lotteriesIndexUrl = `https://storage.googleapis.com/mmparty/${partyId}/lotteries.json`;
    const { data: { lotteries } } = await axios(lotteriesIndexUrl);

    // fetch each lottery result file
    const result = [];
    const res = await Promise.all(lotteries.map((lottery) => this.getIndividualFile(lottery)));
    res.forEach((r) => {
      if (r) result.push(r);
    });


    runInAction(() => { this.awardList = result; });
  };

  getIndividualFile = async (lottery) => {
    let res = null;
    try {
      const lotteryUrl = `https://storage.googleapis.com/mmparty/${lottery}`;
      const { data } = await axios(lotteryUrl);
      res = data;
    } catch {
      console.log('file not found', lottery);
    }
    return res;
  }

  onPrizeResult = (result) => {
    if (result.teamId && result.teamId === this.tid) {
      this.myListWithTitle.push({ title: result.title, award: result.award });
    } else if (result.userIds.includes(this.uid)) {
      this.myListWithTitle.push({ title: result.title, award: result.award });
    }
  }
}
