/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React from 'react';
import PropTypes from 'prop-types';
import { makeObservable, runInAction, observable } from 'mobx';
import { observer } from 'mobx-react';

import styles from './ClientLayout.module.css';

@observer
class ClientLayout extends React.Component {
  domain = window.location.hostname.split('.')[0];

  logo = `https://storage.googleapis.com/mmparty/${this.domain}/logo-big.png?r=${Math.random()}`;

  backgroundDesktop = `https://storage.googleapis.com/mmparty/${this.domain}/background-desktop.jpg?r=${Math.random()}`;

  backgroundMobile = `https://storage.googleapis.com/mmparty/${this.domain}/background-mobile.jpg?r=${Math.random()}`;

  @observable deviceWidth = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    runInAction(() => {
      this.deviceWidth = window.innerWidth;
    });
  }

  render() {
    return (
      <div className={`client-layout ${styles.topCover}`}>
        <img src={this.deviceWidth >= 768 ? this.backgroundDesktop : this.backgroundMobile} alt="background" className={styles.backgroungImg} />

        <div
          style={{ background: 'linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.16) 100%)' }}
          className={styles.titleBar}
        >
          <px-posize
            track-style='{"flexGrow":1}'
            x="18fr 94.9% 18fr"
            y="8fr minmax(0px, 84fr) 8fr"
            md-x="18fr 94.9% 18fr"
            md-y="8fr minmax(0px, 84fr) 8fr"
            sm-x="18fr 94.9% 18fr"
            sm-y="8fr minmax(0px, 84fr) 8fr"
          >
            <div className={styles.text}>
              <div className={styles.text_item}>
                <px-posize
                  track-style='{"flexGrow":1}'
                  area-style='{":before":{"content":"\" \"","display":"inline-block","height":"100%","verticalAlign":"middle"}}'
                  x="0fr 84px 0fr"
                  y="0fr 84px 0fr"
                  md-x="0fr 84px 0fr"
                  md-y="0fr 84px 0fr"
                  sm-x="0fr 60px 0fr"
                  sm-y="0fr 60px 0fr"
                  xxs-x="0fr 42px 0fr"
                  xxs-y="0fr 42px 0fr"
                >
                  <img className={styles.logo} src={this.logo} alt="logo" />
                </px-posize>
              </div>
              <div className={styles.text_spacer} />
              <div className={styles.text_item1}>
                <px-posize
                  track-style='{"flexGrow":1}'
                  x="0fr 100% 0fr"
                  y="23fr minmax(0px, 39fr) 22fr"
                  xxs-x="0fr 100% 0fr"
                  xxs-y="7fr minmax(0px, 39fr) 1fr"
                >
                  <h2 className={styles.partyTitle}>{this.props.partyTitle}</h2>
                </px-posize>
              </div>
            </div>
          </px-posize>
        </div>
      </div>
    );
  }
}

ClientLayout.propTypes = {
  partyTitle: PropTypes.string.isRequired
};

ClientLayout.inStorybook = true;

export default ClientLayout;
