import Cookies from 'js-cookie';
import API from '../apis';
import USER_TOKEN_KEY from '../constants';

export default class Party {
  static getParty = async () => {
    const party = await API.party.getParty();

    return {
      name: party.data?.name ?? '',
      awardList: party.data?.awardList ?? null,
      relative: party.data?.relative ?? false,
      status: party.data?.status,
      canUseEmployeeIdLogin: party.data?.canUseEmployeeIdLogin,
      canUseAnonymousLogin: party.data?.canUseAnonymousLogin,
      canAutoLogin: party.data?.canAutoLogin,
      teamCount: party.data.teamCount,
      enableSession: party.data?.enableSession ?? false,
      enablePoint: party.data?.enablePoint ?? false,
      enableChallenges: party.data?.enableChallenges ?? false
    };
  }

  static listAnnouncement = async () => {
    const token = Cookies.get(USER_TOKEN_KEY);
    const response = await API.party.listAnnouncement(token);
    return response.data;
  }
}
