/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import cn from 'classnames';
import {
  Tabs, Button, Avatar, Popover, Modal
} from 'antd';
import {
  WechatOutlined, TrophyOutlined, UpOutlined, DownOutlined, UserOutlined
} from '@ant-design/icons';
import QRCode from 'qrcode.react';

import i18n from '../../../../I18n';
import ChatRoom from './ChatRoom';
import Award from './Award';
import SlotMachine from './SlotMachine';
import ProgramViewModel from '../../../../viewModels/ProgramViewModel';
import SideBarViewModel from '../../../../viewModels/SideBarViewModel';

import qrcodeIcon from '../../../../assets/popup.png';

import styles from '../program.module.css';

@observer
export default class SideBar extends React.Component {
  @observable deviceWidth = null;

  enterButtonRef = React.createRef();

  constructor(props) {
    super(props);
    this.programViewModel = this.props.viewModel;
    this.sidebarViewModel = new SideBarViewModel();
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  getUrl(url) {
    return `${window.location.origin}/interaction/${url}?uid=${this.props.uid}&tid=${this.props.tid}`;
  }

  resize = () => {
    runInAction(() => {
      this.deviceWidth = window.innerWidth;
    });
  }

  renderQRcode() {

    return (
      <div className={this.props.sessionIsEnabled ? styles.side_push_box2 : styles.side_push_box}>
        <div>
          {window.innerWidth >= 768 && (
            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
              <QRCode
                value={this.getUrl(this.programViewModel.currentActivity?.playerUrl)}
                size={this.props.sessionIsEnabled ? 242 : 128}
              />
            </div>
          )}

          <div className={styles.side_push_text}>
            {this.programViewModel.currentActivity?.description}
          </div>

          {window.innerWidth < 768 && (
            <Button
              target="_blank"
              href={this.getUrl(this.programViewModel.currentActivity?.playerUrl)}
              style={this.props.sessionIsEnabled ? { display: 'none' } : { marginTop: 10 }}
              ref={this.enterButtonRef}
            >
              {i18n.t('open_game')}
            </Button>
          )}
        </div>

      </div>
    );
  }

  renderForm() {
    const teamId = this.props.teamId + 1;

    const url = this.programViewModel.currentActivity?.relatedUrl
      .replace('USER_NAME', this.props.name)
      .replace('EMAIL', this.props.email)
      .replace('EMPLOYEE_ID', this.props.employeeId)
      .replace('TEAM_ID', teamId)
      .replace('DEPARTMENT', this.props.department);

    return (
      <div className={this.props.sessionIsEnabled ? styles.sessionForm : styles.form}>
        <iframe
          src={url}
          title="form"
          width="375px"
          height="350px"
        />
      </div>
    );
  }


  renderSlotMachine() {
    return (
      <div className={styles.slot_machine}>
        <SlotMachine
          key={this.programViewModel?.slotMachine?.id}
          onClick={this.programViewModel.onSlotMachineClick}
          status={this.programViewModel.slotMachine?.status}
        />
      </div>
    );
  }

  renderAvatar() {
    return (
      <Popover
        placement="bottomRight"
        trigger="click"
        title={(
          <div className={styles.popover_box}>
            <div className={styles.popover_name}>{this.props.name}</div>
            <div>{this.props.email}</div>

            {/* {(this.props.teamCount === 1) && null} */}

            {(this.props.teamCount === 2) && (
              <>
                {this.props.tid === 0 && <div>Blue Team</div>}
                {this.props.tid === 1 && <div>White Team</div>}
              </>
            )}

            {(this.props.teamCount > 2) && (<div>{`Team ${this.props.tid + 1}`}</div>)}
          </div>
        )}
        content={(
          <div className={styles.popover_box}>
            <Button onClick={this.props.logout}>{i18n.t('log_out')}</Button>
          </div>
        )}
      >
        <Avatar
          size="large"
          icon={<UserOutlined />}
          style={{ margin: 5, marginRight: 15 }}
        />
      </Popover>
    );
  }

  renderSelector() {
    const activityType = this.programViewModel.currentActivity?.activityType;

    if (this.programViewModel.slotMachine) return this.renderSlotMachine();
    if (activityType === 'form') return this.renderForm();
    if (activityType !== 'form') return this.renderQRcode();
    return null;
  }

  renderActivityBoxWhenEnableSession() {
    const activityType = this.programViewModel.currentActivity?.activityType;
    const isLargerBox = ((activityType === 'form') || this.programViewModel.slotMachine);

    let boxHeight = null;
    if (this.props.sessionIsEnabled) {
      boxHeight = 416;
    } else if (isLargerBox) {
      boxHeight = 350;
    } else {
      boxHeight = 200;
    }

    const sessionScope = this.programViewModel.currentActivity?.sessionScope ?? this.programViewModel.slotMachine?.sessionScope;
    const isQualified = () => {
      if (sessionScope.length === 0) {
        if (!this.props.isDrawBlock) {
          return true;
        }
        return false;
      }
      return sessionScope.every((s) => this.props.myCheckedInSessions.includes(s));
    };
    if (this.programViewModel.hasActivityBox) {
      if (isQualified()) {
        if (this.deviceWidth >= 768) {
          return (
            <>
              <div
                onClick={this.programViewModel.showModal}
                className={styles.qrcodeButtonContainer}
              >
                <img
                  src={qrcodeIcon}
                  alt="qrcode button"
                  className={styles.qrcodeButton}
                />
              </div>
              <Modal
                visible={this.programViewModel.isActivityModalShowed}
                onCancel={this.programViewModel.handleModalCancel}
                footer={null}
                width={activityType === 'form' ? 420 : 395}
                centered
              >
                {this.renderSelector()}
                <h4
                  className={styles.hintText}
                  style={activityType === 'form' || this.programViewModel.slotMachine ? { display: 'none' } : null}
                >
                  {i18n.t('game_qrcode_message')}
                </h4>
              </Modal>
            </>
          );
        }
        const backdropHeight = document.getElementById('profileContainer')?.offsetHeight;
        return (
          <>
            <div
              className={this.programViewModel.isActivityOpened
                ? cn(styles.backdrop, styles.show)
                : styles.backdrop}
              style={{ height: backdropHeight }}
              onClick={this.programViewModel.toggleActivity}
            />
            <div className={this.props.sessionIsEnabled ? styles.sessionActBox : styles.activityBox}>
              <button
                type="button"
                className={this.props.sessionIsEnabled ? styles.session_push_btn : styles.side_push_btn}
                onClick={this.programViewModel.toggleActivity}
              >
                {this.programViewModel.isActivityOpened
                  ? <DownOutlined style={this.props.sessionIsEnabled ? { fontSize: '20px' } : null} />
                  : <UpOutlined style={this.props.sessionIsEnabled ? { fontSize: '20px' } : null} />}
              </button>

              <div
                style={{ '--height': boxHeight }}
                className={this.programViewModel.isActivityOpened
                  ? styles.side_push_card_show
                  : styles.side_push_card_hide}
              >
                {this.renderSelector()}
                <div
                  className={styles.fakeEnterButton}
                  onClick={() => this.enterButtonRef.current.click()}
                  style={activityType === 'form' || this.programViewModel.slotMachine ? { display: 'none' } : null}
                >
                  {i18n.t('open_game')}
                </div>
                <p
                  className={styles.hintText}
                  style={activityType === 'form' || this.programViewModel.slotMachine ? { display: 'none' } : null}
                >
                  {i18n.t('game_qrcode_message')}
                </p>
              </div>
            </div>
          </>
        );
      }
    }

    return null;
  }

  render() {
    const defaultActiveKey = this.programViewModel.hasChat ? '1' : '2';
    const activityType = this.programViewModel.currentActivity?.activityType;
    const isLargerBox = ((activityType === 'form') || this.programViewModel.slotMachine);

    if (this.props.sessionIsEnabled) {
      return this.renderActivityBoxWhenEnableSession();
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          centered
          tabBarGutter={80}
          tabBarStyle={{ margin: 0 }}
          style={{ flexGrow: 1, position: 'relative' }}
          onTabClick={this.sidebarViewModel.onTabClick}
          tabBarExtraContent={this.renderAvatar()}
        >
          {this.programViewModel.hasChat && (
          <Tabs.TabPane
            style={{ height: '100%' }}
            tab={(
              <span>
                <WechatOutlined />
                {i18n.t('chat_room')}
              </span>
            )}
            key="1"
          >
            <ChatRoom viewModel={this.programViewModel} uid={this.props.uid} />
          </Tabs.TabPane>
          )}

          <Tabs.TabPane
            tab={(
              <span>
                <TrophyOutlined />
                {i18n.t('award_result')}
              </span>
            )}
            key="2"
          >
            <Award viewModel={this.sidebarViewModel} />
          </Tabs.TabPane>
        </Tabs>

        <div>
          {this.programViewModel.hasActivityBox && (
            <>
              <button
                type="button"
                className={styles.side_push_btn}
                onClick={this.programViewModel.toggleActivity}
              >
                {this.programViewModel.isActivityOpened ? <DownOutlined /> : <UpOutlined />}
              </button>

              <div
                style={{ '--height': isLargerBox ? 350 : 200 }}
                className={this.programViewModel.isActivityOpened
                  ? styles.side_push_card_show
                  : styles.side_push_card_hide}
              >
                {this.renderSelector()}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {
  viewModel: PropTypes.instanceOf(ProgramViewModel).isRequired,
  uid: PropTypes.string.isRequired,
  tid: PropTypes.number.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  employeeId: PropTypes.string,
  department: PropTypes.string,
  teamId: PropTypes.number,
  logout: PropTypes.func,
  teamCount: PropTypes.number,
  sessionIsEnabled: PropTypes.bool,
  myCheckedInSessions: PropTypes.arrayOf(PropTypes.string),
  isDrawBlock: PropTypes.bool
};

SideBar.defaultProps = {
  name: null,
  email: null,
  employeeId: '',
  department: '',
  teamId: 0,
  logout: null,
  teamCount: null,
  sessionIsEnabled: null,
  myCheckedInSessions: null,
  isDrawBlock: null
};
