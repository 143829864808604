import Cookies from 'js-cookie';

import API from '../apis';
import USER_TOKEN_KEY from '../constants';

export default class ProgramService {

  static async getProgram() {
    const token = Cookies.get(USER_TOKEN_KEY);
    const res = await API.program.getProgram(token);

    return res?.data;
  }
}

