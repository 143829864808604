import API from '../apis';
import BoothModel from '../models/response/Booth';

export default class Booth {
  static async profile(boothNum) {
    try {
      const { data } = await API.booth.profile(boothNum);
      return BoothModel.fromRes(data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async pay(boothNum, point) {
    const res = await API.booth.pay(boothNum, point);
    return res;
  }
}
