import machine01 from './assets/machine/machine_001.png';
import machine02 from './assets/machine/machine_002.png';
import handle01 from './assets/handle/handle_001.png';
import handle02 from './assets/handle/handle_002.png';
import handle03 from './assets/handle/handle_003.png';
import handle04 from './assets/handle/handle_004.png';
import handle05 from './assets/handle/handle_005.png';
import handle06 from './assets/handle/handle_006.png';
import handle07 from './assets/handle/handle_007.png';
import handle08 from './assets/handle/handle_008.png';
import handle09 from './assets/handle/handle_009.png';
import handle010 from './assets/handle/handle_0010.png';
import handle011 from './assets/handle/handle_0011.png';
import handle012 from './assets/handle/handle_0012.png';
import stripe from './assets/group.png';

const assets = [
  {
    id: 'stripe',
    source: stripe
  },
  {
    id: 'machine01',
    source: machine01
  },
  {
    id: 'machine02',
    source: machine02
  },
  {
    id: 'handle01',
    source: handle01
  },
  {
    id: 'handle02',
    source: handle02
  },
  {
    id: 'handle03',
    source: handle03
  },
  {
    id: 'handle04',
    source: handle04
  },
  {
    id: 'handle05',
    source: handle05
  },
  {
    id: 'handle06',
    source: handle06
  },
  {
    id: 'handle07',
    source: handle07
  },
  {
    id: 'handle08',
    source: handle08
  },
  {
    id: 'handle09',
    source: handle09
  },
  {
    id: 'handle010',
    source: handle010
  },
  {
    id: 'handle011',
    source: handle011
  },
  {
    id: 'handle012',
    source: handle012
  }

];

export default assets;
