import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { observer } from 'mobx-react';

import MainPage from './pages/MainPage';
import './App.css';
import './fonts.css';
import './App.less';

@observer
class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={MainPage} />
        </Switch>
      </Router>
    );
  }
}

export default App;
