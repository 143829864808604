/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import i18n from '../../I18n';
import styles from './CollectionButton.module.css';

@observer
export default class CollectionButton extends React.Component {

  render() {
    return (
      <>
        <div
          className={cn(styles.sessionCard, styles.clickable)}
          onClick={this.props.onClick}
        >
          <div className={styles.sessionCard_item}>
            <div className={cn(styles.text, styles.text_layout)}>
              <div className={cn(styles.title_box, styles.title_box_layout)}>
                <div className={styles.title}>{this.props.title || i18n.t('mission_title')}</div>
              </div>
            </div>
          </div>
          <div className={styles.sessionCard_spacer} />
          <div className={styles.sessionCard_item1}>
            <div className={cn(styles.icon, styles.icon_layout)}>
              <RightOutlined className={cn(styles.icon1, styles.icon1_layout)} />
            </div>
          </div>


        </div>
      </>
    );
  }
}

CollectionButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

CollectionButton.defaultProps = {
  title: null
};

