/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { makeObservable, observable, runInAction } from 'mobx';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { isMobile } from 'react-device-detect';

import SideBar from './components/SideBar';
import ProgramViewModel from '../../../viewModels/ProgramViewModel';

import './chat.css';
import styles from './program.module.css';

@observer
class ProgramPage extends React.Component {
  domain = window.location.hostname.split('.')[0];

  background = `https://storage.googleapis.com/mmparty/${this.domain}/background.jpg`;

  @observable deviceWidth = null;

  constructor(props) {
    super(props);
    makeObservable(this);

    runInAction(() => {
      this.viewModel = new ProgramViewModel({
        uid: this.props.uid,
        tid: this.props.tid
      });
    });
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    runInAction(() => {
      this.deviceWidth = window.innerWidth;
    });
  }

  _renderVideo() {
    return (
      <iframe
        title="title"
        src={this.viewModel.program.video}
        className={this.viewModel.orientation === 'landscape'
          ? styles.landscape_video_body
          : styles.portrait_video_body}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    );
  }

  _renderOther() {
    return (
      <div className={styles.page}>
        <div className={styles.video} style={{ backgroundImage: `url(${this.background})` }}>
          <iframe
            title="title"
            src={this.viewModel.program.video}
            className={styles.video_body}
            height={
              this.deviceWidth >= 768
                ? (((this.deviceWidth - 375) / 16) * 9) - 16
                : ((this.deviceWidth / 16) * 9)
            }
            width={this.deviceWidth >= 768 ? this.deviceWidth - 375 : '100%'}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
          <div className={styles.video_title}>
            {this.props.party}
          </div>
        </div>

        {(this.viewModel.hasChat || this.viewModel.hasPublish) && (
          <div className={styles.side}>
            <SideBar
              viewModel={this.viewModel}
              uid={this.props.uid}
              tid={this.props.tid}
              name={this.props.name}
              email={this.props.email}
              department={this.props.department}
              employeeId={this.props.employeeId}
              teamId={this.props.teamId}
              logout={this.props.logout}
              teamCount={this.props.teamCount}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    return this.viewModel.program && (
      <>
        <Helmet>
          <title>{this.viewModel.program.title}</title>
        </Helmet>

        {(!isMobile)
          ? this._renderOther()
          : (
            <DeviceOrientation
              lockOrientation="landscape"
              className={styles.page}
              onOrientationChange={(orientation) => {
                this.viewModel.onOrientationChange(orientation);
                this.resize();
              }}
            >
              {/* Will only be in DOM in landscape */}
              <Orientation
                orientation="landscape"
                alwaysRender
                className={this.viewModel.orientation === 'landscape' ? styles.landscape_video : styles.portrait_video}
              >
                {this._renderVideo()}
                {this.viewModel.orientation === 'portrait' && (
                  <div className={styles.video_title}>
                    {this.props.party}
                  </div>
                )}
              </Orientation>

              {/* Will stay in DOM, but is only visible in portrait */}
              <Orientation
                orientation="portrait"
                alwaysRender
                className={(this.viewModel.hasChat || this.viewModel.hasPublish) && (this.viewModel.orientation === 'portrait')
                  ? styles.side
                  : styles.noSide}
              >
                <SideBar
                  viewModel={this.viewModel}
                  uid={this.props.uid}
                  tid={this.props.tid}
                  name={this.props.name}
                  email={this.props.email}
                  employeeId={this.props.employeeId}
                  department={this.props.department}
                  teamId={this.props.teamId}
                  logout={this.props.logout}
                  teamCount={this.props.teamCount}
                />
              </Orientation>
            </DeviceOrientation>
          )}
      </>
    );
  }
}

ProgramPage.propTypes = {
  party: PropTypes.string.isRequired,
  teamCount: PropTypes.number.isRequired,
  uid: PropTypes.string.isRequired,
  tid: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  employeeId: PropTypes.string,
  department: PropTypes.string,
  teamId: PropTypes.number,
  logout: PropTypes.func.isRequired
};

ProgramPage.defaultProps = {
  employeeId: '',
  department: '',
  teamId: 0
};

export default ProgramPage;
