/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Drawer, Input, Modal, Spin
} from 'antd';
import i18n from '../../I18n';
import pointIcon from '../../assets/pointIcon.svg';
import leftArrow from '../../assets/leftArrowIcon.svg';
import greenCheck from '../../assets/greenCheck.svg';
import transferSuccess from '../../assets/transferSuccess.png';
import networkError from '../../assets/networkError.png';

import styles from './PointsScreen.module.css';
import Button from './components/Button';
import BottomDrawer from './components/BottomDrawer';
import { TRANSFER_RESULT } from '../../viewModels/PointsViewModel';

@observer
export default class PointsScreen extends React.Component {
  CustomSpin = (
    <Spin
      indicator={(
        <LoadingOutlined
          style={{
            fontSize: 24,
            color: '#333'
          }}
          spin
        />
      )}
    />
  )

  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.props.vm.didMount();
  }

  renderTransactionList() {
    const { vm } = this.props;
    if (vm.isLoading.transaction) {
      return (
        <div className={styles.emptyContainer}>
          {this.CustomSpin}
        </div>
      );
    }

    if (!vm.transactionList.length) {
      return (
        <div className={styles.emptyContainer}>
          {i18n.t('points_no_data')}
        </div>
      );
    }

    const Item = ({ item }) => (
      <div className={styles.points_item}>
        <div className={styles.left}>
          <div className={styles.title}>
            {item.title}
          </div>
          <div className={styles.time}>
            {item.dateInFormat}
          </div>
        </div>

        <div className={styles.right}>
          <img
            className={styles.icon}
            src={pointIcon}
            alt="point"
          />
          <div className={styles.point}>
            {item.pointForDisplay}
          </div>
        </div>
      </div>
    );

    return (
      <div
        className={styles.points_list}
        onScroll={vm.onScrollList}
      >
        {vm.transactionList?.map((item) => (
          <Item item={item} />
        ))}
      </div>
    );
  }

  renderBoothDrawer() {
    const { vm } = this.props;

    return (
      <BottomDrawer
        visible={vm.isBoothDrawerShown}
        onClose={vm.closeBoothDrawer}
      >
        <div className={styles.booth_drawer}>
          <div className={styles.title}>
            {i18n.t('booth_drawer_title')}
          </div>

          <div className={styles.input_container}>
            <span className={styles.label}>
              {i18n.t('booth_id_label')}
            </span>
            <Input
              onChange={vm.onChangeBoothNum}
              className={cn(styles.input, vm.boothNumError && styles.error, 'boothNumberInput')}
              size="large"
              value={vm.boothNum}
              allowClear
            />

            {vm.boothNumError && (
              <span className={styles.error_message}>
                {vm.boothNumError}
              </span>
            )}
          </div>

          <div className={styles.flexGrow} />

          <Button
            withIcon
            className={styles.button}
            onClick={vm.getBoothInfo}
          >
            {i18n.t('booth_drawer_enter_amount')}
          </Button>
        </div>
      </BottomDrawer>
    );
  }

  renderTransferDrawer() {
    const { vm } = this.props;

    return (
      <BottomDrawer
        visible={vm.isTransferDrawerShown}
        onClose={vm.closeTransferDrawer}
      >
        <div className={styles.transfer_drawer}>
          <div className={styles.title}>
            {`${i18n.t('booth_title')}：${vm.boothNum} ${vm.boothName}`}
          </div>

          <div className={styles.input_container}>
            <img
              className={styles.icon}
              src={pointIcon}
              alt="point"
            />

            <Input
              className={styles.amount}
              value={vm.amount}
              readOnly
              inputMode="none"
            />
          </div>

          <div className={styles.number_keyboard}>
            {['1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0', 'back'].map((v) => (
              <button
                className={styles.number_button}
                onClick={v.length !== 0 ? () => vm.onChangeAmount(v) : () => {}}
                type="button"
              >
                {v === 'back'
                  ? (
                    <img
                      src={leftArrow}
                      alt="left arrow"
                    />
                  )
                  : v}
              </button>
            ))}
          </div>

          <div className={styles.flexGrow} />

          <Button
            withIcon
            className={styles.button}
            onClick={vm.showConfirmTransferModal}
            disabled={vm.isTransferDisabled}
            loading={vm.isLoading.transfer}
          >
            {i18n.t('transfer_button')}
          </Button>
        </div>
      </BottomDrawer>
    );
  }

  renderConfirmTransferModal() {
    const { vm } = this.props;

    return (
      <Modal
        visible={vm.isConfirmTransferModalShown}
        onCancel={vm.closeConfirmTransferModal}
        footer={null}
        width={300}
        centered
        className="confirm-transfer-modal"
      >
        <div className={styles.confirmModal}>
          <p>
            {i18n.t('transfer_confirm_text', { amount: vm.amount, booth: `${vm.boothNum}-${vm.boothName}` })}
          </p>
          <div className={styles.buttons}>
            <Button
              onClick={vm.closeConfirmTransferModal}
              className={styles.cancelButton}
            >
              <span className={styles.black}>
                {i18n.t('cancel')}
              </span>
            </Button>
            <Button
              onClick={() => {
                vm.closeConfirmTransferModal();
                vm.onTransfer();
              }}
            >
              {i18n.t('confirm')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  renderResultScreen() {
    const { vm } = this.props;

    const content = (() => {
      switch (vm.result) {
        case TRANSFER_RESULT.Success:
          return (
            <div className={styles.result_success}>
              <div className={styles.flexGrow} />
              <div>
                <img
                  src={greenCheck}
                  alt="green check"
                  className={styles.greenCheck}
                />
                <div className={styles.title}>
                  {i18n.t('result_success_title')}
                </div>
              </div>

              <div className={styles.desc}>
                {`${i18n.t('transfer_desc', { amount: vm.amount })} `}
                <br />
                <span className={styles.bold}>
                  {`${vm.boothNum}-${vm.boothName ?? ''}`}
                </span>
              </div>

              <div className={styles.flexGrow} />

              <img
                src={transferSuccess}
                alt="success"
                className={styles.illus}
              />

              <div className={styles.flexGrow} />

              <Button onClick={vm.clearResult}>
                {i18n.t('close_button')}
              </Button>
            </div>
          );
        case TRANSFER_RESULT.Fail:
          return (
            <div className={styles.result_fail}>
              <img
                src={networkError}
                alt="network error"
                className={styles.illus}
              />

              <p className={styles.text}>
                {i18n.t('result_fail_title')}
              </p>
              <p className={styles.text}>
                {i18n.t('result_network_error')}
              </p>

              <Button
                className={styles.button}
                onClick={vm.clearResult}
              >
                {i18n.t('close_button')}
              </Button>
            </div>
          );
        default:
          return null;
      }
    })();

    return (
      <Drawer
        visible={!!vm.result}
        onClose={vm.clearResult}
        width="min(550px, 100%)"
        closable={false}
        bodyStyle={{
          backgroundColor: '#F7F7F7',
          display: 'flex',
          flexDirection: 'column',
          rowGap: 26
        }}
        destroyOnClose
      >
        {content}
      </Drawer>
    );
  }

  render() {
    const { vm } = this.props;

    return (
      <>
        <Drawer
          visible={this.props.vm.isScreenShow}
          width="min(550px, 100%)"
          closable={false}
          bodyStyle={{
            backgroundColor: '#F7F7F7',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh'
          }}
        >
          <LeftOutlined
            className={styles.leftOutlinedIcon}
            onClick={this.props.vm.closeScreen}
          />


          <div className={styles.main}>
            <div className={styles.state_container}>
              <div className={styles.state}>
                <div className={styles.state_title}>{i18n.t('points_currently')}</div>
                <div className={styles.state_number}>
                  {vm.isLoading.transaction
                    ? this.CustomSpin
                    : this.props.vm.currentPoint}
                </div>
                <div className={styles.state_points}>{i18n.t('points_unit')}</div>
              </div>
              <div className={styles.state}>
                <div className={styles.state_title}>{i18n.t('points_used')}</div>
                <div className={styles.state_number}>
                  {vm.isLoading.transaction
                    ? this.CustomSpin
                    : this.props.vm.usedPoint}
                </div>
                <div className={styles.state_points}>{i18n.t('points_unit')}</div>
              </div>
            </div>

            <div className={styles.points_detail_title_area}>
              <h5 className={styles.points_detail_title}>
                {i18n.t('points_detail')}
              </h5>
              <p className={styles.points_detail_updated_at}>
                {`${i18n.t('updated_at')} ${this.props.vm.updatedAt}`}
              </p>
            </div>

            {this.renderTransactionList()}

            <div className={styles.bottomSpace} />

            <Button
              withIcon
              onClick={this.props.vm.showBoothDrawer}
              className={styles.usePointButton}
            >
              {i18n.t('use_points_btn')}
            </Button>

          </div>
        </Drawer>

        {this.renderBoothDrawer()}
        {this.renderTransferDrawer()}
        {this.renderConfirmTransferModal()}

        {this.renderResultScreen()}
      </>
    );
  }
}

PointsScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  vm: PropTypes.object.isRequired
};

PointsScreen.defaultProps = {

};

