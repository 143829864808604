import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Form, Button, Select
} from 'antd';
import i18n from '../../../I18n';

import styles from '../../../common.module.css';

@observer
class Activate extends React.Component {
  relativeCount = 5;

  renderRelativeCountOptions = () => {
    const list = [];

    for (let i = 1; i <= this.relativeCount; i += 1) {
      list.push(<Select.Option key={i} value={i}>{i}</Select.Option>);
    }

    return list;
  }

  render() {
    return (
      <>
        <div>
          <div className={styles.title}>{i18n.t('choose_souvenirs')}</div>

          <Form
            name="basic"
            layout="vertical"
            onFinish={(fields) => this.props.onActivateClick(fields)}
          >
            {this.props.party.awardList && (
              <Form.Item
                label={i18n.t('souvenirs')}
                name="award"
                rules={[
                  {
                    required: true,
                    message: i18n.t('input_souvenirs')
                  }
                ]}
              >
                <Select>
                  {this.props.party.awardList.map((award) => (
                    <Select.Option key={award} value={award}>{award}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {this.props.party.relative && (
              <Form.Item
                label={i18n.t('dependants')}
                name="relativeCount"
                rules={[
                  {
                    required: true,
                    message: i18n.t('input_dependants')
                  }
                ]}
              >
                <Select>
                  {this.renderRelativeCountOptions()}
                </Select>
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" block className={styles.block_btn}>
                {i18n.t('confirm')}
              </Button>
              <div className={styles.subtitle}>
                {i18n.t('confirm_message')}
              </div>
            </Form.Item>

          </Form>
        </div>
      </>
    );
  }
}

Activate.propTypes = {
  onActivateClick: PropTypes.func.isRequired,
  party: PropTypes.shape({
    awardList: PropTypes.arrayOf(PropTypes.string),
    relative: PropTypes.bool
  }).isRequired
};

export default Activate;
