import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { RightOutlined, LoadingOutlined } from '@ant-design/icons';
import Spin from 'antd/es/spin';

import styles from './Button.module.css';

export default function Button(props) {
  const {
    children,
    withIcon,
    onClick,
    className,
    disabled,
    loading,
    ...ps
  } = props;

  return (
    <button
      type="button"
      className={cn(styles.button, disabled && styles.disabled, className)}
      onClick={onClick}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ps}
    >
      {loading
        ? (
          <div className={cn(styles.spin_container)}>
            <Spin
              indicator={(
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                    color: '#fff'
                  }}
                  spin
                />
              )}
            />
          </div>
        )
        : (
          <div className={cn(styles.main)}>
            {children}

            {withIcon && (
              <RightOutlined className={styles.icon} />
            )}
          </div>
        )}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  withIcon: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

Button.defaultProps = {
  children: null,
  withIcon: false,
  onClick: () => {},
  className: '',
  disabled: false,
  loading: false
};

Button.inStorybook = true;
