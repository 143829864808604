import Cookies from 'js-cookie';
import USER_TOKEN_KEY from '../constants';
import { getHost, request } from './utils';

export const profile = (boothNum) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/boothNum/${boothNum}/profile`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${Cookies.get(USER_TOKEN_KEY)}`
    }
  };
  return request(options);
};

export const pay = (boothNum, point) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/boothNum/${boothNum}/payment`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `JWT ${Cookies.get(USER_TOKEN_KEY)}`
    },
    data: {
      point
    }
  };
  return request(options);
};
