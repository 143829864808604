import Cookies from 'js-cookie';

import API from '../apis';
import PointRecord from '../models/response/PointRecord';
import USER_TOKEN_KEY from '../constants';

export default class Me {
  static async login(email, password) {
    const res = await API.me.login(email, password);
    Cookies.set(USER_TOKEN_KEY, res?.data?.accessToken ?? null, { expires: 7 });

    return {
      isActivated: res.data.isActivated,
      isAdmin: res.data.isAdmin,
      isBlock: res.data.isBlock,
      isDrawBlock: res.data.isDrawBlock
    };
  }

  static async loginWithEmployeeId(employeeId) {
    const res = await API.me.loginWithEmployeeId(employeeId);
    Cookies.set(USER_TOKEN_KEY, res?.data?.accessToken ?? null, { expires: 7 });

    return {
      isActivated: res.data.isActivated,
      isAdmin: res.data.isAdmin,
      isBlock: res.data.isBlock,
      isDrawBlock: res.data.isDrawBlock
    };
  }

  static async loginWithAnonymous(name, department) {
    const res = await API.me.loginWithAnonymous(name, department);
    Cookies.set(USER_TOKEN_KEY, res?.data?.accessToken ?? null, { expires: 7 });

    return {
      isActivated: res.data.isActivated,
      isAdmin: res.data.isAdmin,
      isBlock: res.data.isBlock,
      isDrawBlock: res.data.isDrawBlock
    };
  }

  static async logout() {
    try {
      const token = Cookies.get(USER_TOKEN_KEY);
      Cookies.remove(USER_TOKEN_KEY);
      if (token) {
        await API.me.logout(token);
      }
    } catch (err) {
      // ignore
    }
  }

  static async profile() {
    try {
      const token = Cookies.get(USER_TOKEN_KEY);
      const res = await API.me.profile(token);
      const { me } = res.data;

      return {
        nickname: me.nickname,
        employeeId: me.employeeId,
        department: me.department,
        jobTitle: me.jobTitle,
        teamId: me.teamId,
        isActivated: me.isActivated,
        isAdmin: me.isAdmin,
        isBlock: me.isBlock,
        name: me.name,
        id: me.id,
        email: me.email,
        isDrawBlock: me.isDrawBlock,
        isCheckIn: me.isCheckIn,
        usedPoints: me.usedPoints,
        completedMission: me.completedMission
      };

    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static async activate(award, relativeCount) {
    const token = Cookies.get(USER_TOKEN_KEY);
    return API.me.activate(token, award, relativeCount);
  }

  static async getAward() {
    const token = Cookies.get(USER_TOKEN_KEY);
    const res = await API.me.getAward(token);

    return res.data;
  }

  static async redeem(points) {
    const token = Cookies.get(USER_TOKEN_KEY);
    return API.me.redeem(token, points);
  }

  static hasToken() {
    return !!Cookies.get(USER_TOKEN_KEY);
  }

  static getToken() {
    return Cookies.get(USER_TOKEN_KEY);
  }

  static async transaction() {
    const token = Cookies.get(USER_TOKEN_KEY);
    const { data } = await API.me.transaction(token);

    return {
      currentPoint: data.currentPoint,
      usedPoint: data.usedPoint,
      results: {
        data: data.results.data?.map((d) => PointRecord.fromRes(d)),
        anchor: data.results.anchor
      }
    };
  }
}
